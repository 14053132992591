import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[cmAutoScroll]',
})
export class AutoScrollDirective {
  constructor(private el: ElementRef) {}

  scrollToElement(value: string): void {
    const view = this.el.nativeElement.closest(value);
    view?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
