import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { category1, category2 } from '../categories';
import { CaseService } from '@shared/services/case.service';
import { CaseModel } from '../case.model';

@Component({
  selector: 'cm-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.scss'],
})
export class EditPageComponent {
  public data: CaseModel = {
    id: '',
    status: 'open',
    summary: '',
    district: '',
    referenceId: '',
    caseType: 'customer_opinion',
    createdAt: new Date(),
  };
  private modelSub: Subscription = Subscription.EMPTY;
  public editCaseForm: FormGroup = new FormGroup({
    fromStation: new FormControl(''),
    toStation: new FormControl(''),
    line: new FormControl(''),
    vehicleNumber: new FormControl(''),
    staffMemberId: new FormControl({ value: '', disabled: true }),
    role: new FormControl({ value: '', disabled: true }),
    category1: new FormControl(''),
    category2: new FormControl(''),
    category3: new FormControl(''),
    description: new FormControl(''),
    eventDate: new FormControl({ value: '', disabled: true }),
    reportedAt: new FormControl({ value: '', disabled: true }),
    isMissingInformation: new FormControl(false),
    isNothingWrong: new FormControl(false),
    customerName: new FormControl({ value: '', disabled: true }),
    customerEmail: new FormControl({ value: '', disabled: true }),
    customerPhone: new FormControl({ value: '', disabled: true }),
    requiredCustomerFeedbackAction: new FormControl(false),
  });
  public cat1 = category1; //TODO - temporarily attached to the front
  public cat2 = category2; //TODO - temporarily attached to the front

  constructor(
    private caseService: CaseService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.modelSub.unsubscribe();
  }

  ngOnInit(): void {
    this.caseService
      .getCase({ id: this.route.snapshot.params['id'] })
      .subscribe(data => {
        this.data = data.case;
        this.updateFormModel(data.case);
      });
  }

  updateFormModel(model: CaseModel): void {
    this.editCaseForm.patchValue({
      ...model,
    });
    const descriptionControl = this.editCaseForm.get('description');
    if (descriptionControl) {
      const descriptionValue = descriptionControl.value;
      if (descriptionValue && descriptionValue[0]?.content?.[0]?.text) {
        descriptionControl.patchValue(
          descriptionValue[0].content[0].text.replace(/<[^>]*>/g, '')
        );
      }
    }
  }

  send(): void {
    if (!this.editCaseForm.valid) {
      return;
    }

    this.sendForm(this.editCaseForm.value);
  }

  sendForm(model: any): void {
    let sentToFormated = model.sentTo ? [...model.sentTo].flat() : null;
    const requestModel = {
      ...model,
      category1: model.category1 ?? '',
      category2: model.category2 ?? '',
      category3: model.category3 ?? '',
      staffMemberId: model.staffMemberId ?? '',
      role: model.role ?? '',
      sentTo: sentToFormated ?? ['Other'],
    };

    this.caseService
      .update(this.route.snapshot.params['id'], {
        ...requestModel,
      })
      .subscribe(res => {
        this.router.navigate(['/case', this.route.snapshot.params['id']]);
      });
  }

  goBack(): void {
    history.back();
  }
}
