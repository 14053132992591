import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fromDistance' })
export class FromDistancePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: Date | undefined): string {
    if (value === undefined) {
      return '-';
    }

    const date = new Date(value);

    const now = new Date();
    const age = now.getTime() - date.getTime();
    const seconds = Math.floor(age / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (seconds < 60) {
      return 'A few seconds ago';
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 6) {
      return `${hours} hours ago`;
    } else if (hours < 24) {
      return now.getDate() === date.getDate() ? 'Today' : 'Yesterday';
    } else if (days === 1) {
      return 'Yesterday';
    } else if (days < 7) {
      return `${days} days ago`;
    } else {
      return this.datePipe.transform(value, 'yyyy-MM-dd | hh:mm') ?? '-';
    }
  }
}
