import { Component, Input } from '@angular/core';
import { CaseModel } from '../../case.model';

@Component({
  selector: 'cm-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss'],
})
export class BasicInfoComponent {
  @Input() data!: CaseModel;

  public typeText(type: string): string {
    switch (type) {
      case 'customer_opinion':
        return 'Customer Feedback';
      default:
        return 'Unknown';
    }
  }
}
