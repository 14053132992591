<div class="main-header">
  <img class="logo-img" src="/assets/icons/vr_logo.svg" alt="logo img" />
  <mat-form-field appearance="outline" class="district-select">
    <mat-label>District</mat-label>
    <mat-select
      (selectionChange)="handleSelection($event)"
      [(value)]="selected"
      disabled>
      @for (district of districts; track district) {
        <mat-option [value]="district.value">{{
          district.viewValue
        }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div class="space-pusher"></div>
  <cm-user-menu></cm-user-menu>
</div>

<div class="container">
  <div class="sidenav" [class.sidenav--unfolded]="unfolded">
    <div class="sidenav__content" [class.sidenav__content--unfolded]="unfolded">
    <div class="sidenav__fold-button" (click)="toggleSidebar()">
      <span *ngIf="!unfolded" class="material-symbols-outlined">
        chevron_right
        </span>
        <span *ngIf="unfolded" class="material-symbols-outlined">
          chevron_left
          </span>
      </div>
      <div class="sidenav__header" [class.sidenav__header--unfolded]="!unfolded">
        <div class="app-logo">
          <span class="material-symbols-outlined">bolt</span>
          <span>
            CM
          </span>
        </div>
        <p class="sidenav__header--subtitle" *ngIf="unfolded">Case management</p>
      </div>
      <a
        [routerLink]="['/cases']"
        class="admin-menu--item"
        [ngClass]="{ 'admin-menu--item-current': isCurrent('/cases') }"
        [matTooltipPosition]="'after'"
        [matTooltip]="unfolded ? '' : 'Customer feedback'">
        <span class="material-symbols-outlined">feedback</span>
        <span *ngIf="unfolded">Customer feedback</span>
      </a>
      <a href="https://thr2.fawful.se/" class="admin-menu--item"
        [matTooltipPosition]="'after'"
        [matTooltip]="unfolded ? '' : 'THR'">
        <span class="material-symbols-outlined">account_box</span>
        <span *ngIf="unfolded">THR</span>
      </a>
      <a href="https://ecase.fawful.se/"
        class="admin-menu--item"
        [matTooltipPosition]="'after'"
        [matTooltip]="unfolded ? '' : 'Ecase cases'">
        <span class="material-symbols-outlined">topic</span>
        <span *ngIf="unfolded">Ecase cases</span>
      </a>
      <div class="space-pusher"></div>
    </div>
  </div>

  <div class="content" [class.content--unfolded]="!unfolded">
    <app-loader-overlay></app-loader-overlay>
    <router-outlet></router-outlet>
  </div>
</div>
