import { Component, ViewChild } from '@angular/core';
import { CategoryService } from '@shared/services/category.service';
import { CaseTypeInstance, CategoryInstance, CategoryListRequest } from '../category.model';
import {
  MatCell, MatCellDef,
  MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable,
  MatTableDataSource, MatTextColumn,
} from '@angular/material/table';
import { VrStorybookLibModule } from '@vr/vr-storybook-lib';
import { NgForOf, NgIf } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { CategoryDetailsComponent } from '../category-details/category-details.component';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material/select';
import { MatButton } from '@angular/material/button';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateFormatPipe } from '@shared/pipes/date-format.pipe';
import { MatInput } from '@angular/material/input';
import { MatSort, MatSortModule } from '@angular/material/sort';

@Component({
  selector: 'cm-list-categories',
  templateUrl: './list-categories.component.html',
  styleUrl: './list-categories.component.scss',
  standalone: true,
  imports: [
    MatLabel,
    MatTable,
    MatTextColumn,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    VrStorybookLibModule,
    MatPaginator,
    MatHeaderRow,
    MatRow,
    MatRowDef,
    MatHeaderRowDef,
    MatCellDef,
    MatHeaderCellDef,
    NgIf,
    MatFormField,
    MatSelect,
    MatOption,
    MatButton,
    ReactiveFormsModule,
    NgForOf,
    DateFormatPipe,
    MatInput,
    MatSortModule,
  ],
})
export class ListCategoriesComponent {
  totalRecords = 0;
  displayedColumns: string[] = ['code', 'name', 'deployed', 'enabled', 'validFrom'];
  dataSource = new MatTableDataSource<CategoryInstance>();

  caseTypeInstances: CaseTypeInstance[] = [];
  selectedCaseTypeInstance?: CaseTypeInstance | null;
  caseTypeCategories: CategoryInstance[] = [];

  categoryFilter: FormGroup = new FormGroup({ caseTypeInstanceId: new FormControl(''), name: new FormControl('') });

  peopleRequestBody: CategoryListRequest = {
    page: 1,
    pageSize: 10,
  };

  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private categoryService: CategoryService,
    public dialog: MatDialog,
  ) {
    this.getCaseTypeInstances();
  }

  pageChangeEvent(event: { pageIndex: number; pageSize: number }) {

  }

  getTableData(): void {
    console.log(this.sort);
    console.log(this.paginator);
    this.peopleRequestBody = {
      ...this.peopleRequestBody,
      ...this.categoryFilter.value,
    };

    this.categoryService
      .getAllCategories(this.peopleRequestBody)
      .subscribe({
        next: response => {
          this.caseTypeCategories = response.categories;
          this.totalRecords = response.totalRecords;
          this.dataSource = new MatTableDataSource(response.categories);
        },
      });
  }

  getCaseTypeInstances(): void {
    this.categoryService.getAllCaseTypeInstances().subscribe({
      next: response => {
        this.caseTypeInstances = response;

        this.categoryFilter
          .get('caseTypeInstanceId')
          ?.setValue(this.caseTypeInstances[0]?.id);

        this.getTableData();
      },
    });
  }

  onDropdownChange(event: MatSelectChange): void {
    this.selectedCaseTypeInstance = event.value;
    this.getTableData();
  }

  filterCategories(): void {
    this.getTableData();
  }

  onRowClick(category: CategoryInstance): void {
    const dialogRef = this.dialog.open(CategoryDetailsComponent, {
      data: {
        category,
        caseTypeInstances: this.caseTypeInstances,
        caseTypeInstanceCategories: this.caseTypeCategories,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTableData();
      }
    });
  }
}
