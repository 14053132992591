import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { environment } from '../../../../environments/environment';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(
    public router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.validateAccessByToken().then(isLoggedIn => {
      if (isLoggedIn) {
        return true;
      } else {
        Auth.federatedSignIn({
          customProvider: environment.cognito.idpName,
          customState: JSON.stringify({ dynamicRedirectUrl: state.url }),
        });
        return false;
      }
    });
  }
}
