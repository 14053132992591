import { Component, Input } from '@angular/core';
import { BradcrumbComponent } from '../bradcrumb/bradcrumb.component';

@Component({
  selector: 'cm-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  standalone: true,
  imports: [BradcrumbComponent],
})
export class PageHeaderComponent {
  @Input() title!: string;
  @Input() breadcrumbList!: { label: string; url: string; hidden: boolean }[];
}
