import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loader-overlay',
  templateUrl: './loader-overlay.component.html',
  styleUrls: ['./loader-overlay.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, CommonModule],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class LoaderOverlayComponent {
  constructor(public loader: LoaderService) {}

  @Input() isLoading!: boolean;
}
