import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute } from '@angular/router';
import { CustomerInfo } from '../../case.model';
import { CaseService } from '@shared/services/case.service';

@Component({
  selector: 'cm-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss'],
})
export class CustomerInfoComponent {
  @Input() data!: CustomerInfo;

  constructor(
    private clipboard: Clipboard,
    public caseService: CaseService,
    private route: ActivatedRoute
  ) {}

  copyToClipboard(value: string) {
    this.clipboard.copy(value);
  }

  getCustomerInfo() {
    this.caseService
      .getCaseCustomerInfo({ id: this.route.snapshot.params['id'] })
      .subscribe(data => {
        this.data = data.customerInfo;
      });
  }
}
