import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
@Component({
  selector: 'app-loader-overlay',
  templateUrl: './loader-overlay.component.html',
  styleUrls: ['./loader-overlay.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class LoaderOverlayComponent {
  constructor(public loader: LoaderService) {}

  @Input() isLoading!: boolean;
}
