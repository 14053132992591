import { Component, Input } from '@angular/core';
import { VrStorybookLibModule } from '@vr/vr-storybook-lib';

@Component({
  selector: 'cm-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  standalone: true,
  imports: [VrStorybookLibModule],
})
export class PageHeaderComponent {
  @Input() title!: string;
  @Input() breadcrumbList!: { label: string; url: string; hidden: boolean }[];
}
