import {
  Directive,
  ElementRef,
  HostListener,
  Optional,
  Renderer2,
} from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[cmFocusTextarea]',
})
export class FocusTextareaDirective {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Optional() private ngModel: NgModel
  ) {}

  @HostListener('focus', ['$event'])
  onFocusEvent() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', '129px');
    this.scrollToEnd();
  }

  changeTextareaDimension() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', 'auto');
  }

  scrollToEnd(): void {
    const scrollHeight = document.documentElement.scrollHeight;
    window.scrollTo({
      top: scrollHeight,
      behavior: 'smooth',
    });
  }
}
