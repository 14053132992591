export const isDifferenceMoreThan7Days = (givenDate: Date) => {
  const today = new Date();

  const givenDateObj = new Date(givenDate);

  const timeDiff = today.getTime() - givenDateObj.getTime();
  const dayDiff = timeDiff / (1000 * 3600 * 24);

  return dayDiff < 7;
};
