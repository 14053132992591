import { Component, Input } from '@angular/core';
import { DescriptionType } from 'src/app/shared/enums';
import { CaseModel } from '../../case.model';

@Component({
  selector: 'cm-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent {
  @Input() data!: CaseModel;

  descriptionType = DescriptionType;
}
