export const category1 = [
  'Bemötande/kompetens',
  'Biljetter och priser',
  'Biljettkontroll',
  'Ersättningstrafik',
  'Felanmälan/synpunkt',
  'Förares körsätt',
  'Hittegods',
  'Miljö',
  'Oljud/Buller',
  'Ordning/säkerhet',
  'Realtidsinformation',
  'Skada person',
  'Skada egendom',
  'Störningsinformation',
  'Tidtabell',
  'Tillgänglighet',
  'Trafikförvaltningen',
  'Trafikinformation',
  'Trafikplanering',
  'Trafikstörning',
  'Trängsel',
];

export const category2 = [
  'Akut ersättningstrafik',
  'Annan tjänst/komplettering',
  'Använder mobiltelefon, hörlurar etc.',
  'Automatiska utrop på stn/hpl/brygga',
  'Avgångs- och ankomsttid',
  'Barn i trafiken',
  'Beredskap för stora händelser, utrymning, skyddsrum m.m.',
  'Biljettförsäljning app',
  'Biljettkontrollant',
  'Buller/oljud från fordon/anläggning',
  'Buskage',
  'Bytespunkter och passning mellan trafikslag',
  'Digital skylt',
  'Energianvändning och drivmedel',
  'Fall vid färd',
  'Fast skyltning',
  'Felanmälan anläggning',
  'Felanmälan fordon/fartyg',
  'Förare',
  'Fordon, övrigt',
  'Fordonstyp',
  'Förseningsersättning',
  'Försvunnet hittegods',
  'Frånåkning',
  'Full/trasig papperskorg, sophantering',
  'Hänsyn i trafiken',
  'Hastighet/obekväm/ryckig körning',
  'Högtalarutrop',
  'Hyra, upplåta lokal/anläggning/fordon',
  'Klämd i fordonsdörrar',
  'Klämd i spärr',
  'Klotter/skadegörelse anläggning',
  'Klotter/skadegörelse fordon/fartyg',
  'Kontaktlöst',
  'Kort',
  'Körtider och restider',
  'Linjekarta/linjeinformation',
  'Linjenummer/destinationsskylt på fordon/fartyg',
  'Linjesträckning',
  'Linjeutbud och linjesträckningar',
  'Nedladdning/validering biljettautomat eller stolpe',
  'Oljud från underhållsarbete/byggnation',
  'Önskar fler/färre/plats/tid',
  'Önskemål/synpunkter om skyltning',
  'Önskemål/synpunkter om utformning (bänk, hiss, papperskorg etc)',
  'Övrig personal',
  'Övriga egendomsskador (ej fordon)',
  'Övriga personskador (ej fordon)',
  'Påkörning',
  'Plankning',
  'Prator (fel information eller fungerar inte)',
  'Processer och rutiner rörande hittegods',
  'Realtidsinformation/väntetidsvisning i app',
  'Realtidsinformation/väntetidsvisning på digital skylt',
  'Realtidsinformation/väntetidsvisning på webbplats',
  'Regelverk och resevillkor',
  'Reklam och riktlinjer',
  'Reseplaneraren, fel',
  'Reseplaneraren, utformning/funktion',
  'Rökning',
  'Skadedjur',
  'Snöröjning/halkbekämpning',
  'Städning/lukt anläggning',
  'Städning/lukt fordon/fartyg',
  'Stations-, bytes- eller destinationsutrop på fordon/fartyg',
  'Stationsvärd/spärrpersonal',
  'Störande beteende',
  'Störd avgång (utebliven, tidig, sen, ej inväntat anslutning)',
  'Störningsanslag/-dekaler eller tillfällig skyltning planerad störning',
  'Störningsinformation från personal',
  'Störningsinformation i app',
  'Störningsinformation på digital skylt',
  'Störningsinformation webbplats',
  'Synpunkt webbplats och app',
  'Teknisk utrustning (ex spärr, biljettautomat m.m.)',
  'Tidtabeller (anslag)',
  'Tiggeri/försäljare/musikanter',
  'Tillgänglighet anläggning',
  'Tillgänglighet fordon',
  'Tillgänglighet vid ombyggnation',
  'Trafikförsörjningsprogram',
  'Trängsel ombord på fordon/fartyg',
  'Trängsel/flöden biljetthall/terminal',
  'Trängsel/flöden plattform/hållplats/brygga',
  'Trängsel/flöden trappa/rulltrappa',
  'Trygghetsresurser eller kameror',
  'Turtäthet',
  'Utformning',
  'Utsläpp och kemikalier',
  'Vagnordning, husdjursvagnar m.m.',
  'Vägutformning/gaturum/övergångsställe/stadsplanering',
  'Val av trafiklösning, fordonstyp',
  'Ventilation, temperatur',
];

// TODO remove if we don't use in the future
// export const category2WithSubcat = [
//   {
//     name: 'Bemötande/kompetens',
//     values: [
//       'Biljettkontrollant',
//       'Förare',
//       'Stationsvärd/spärrpersonal',
//       'Övrig personal'
//     ]
//   },
//   {
//     name: 'Biljetter och priser',
//     values: [
//       'Biljettförsäljning app',
//       'Kontaktlöst',
//       'Kort',
//       'Nedladdning/validering biljettautomat eller stolpe',
//       'Regelverk och resevillkor'
//     ]
//   },
//   {
//     name: 'Biljettkontroll',
//     values: [
//       'Önskar fler/färre/plats/tid'
//     ]
//   },
//   {
//     name: 'Ersättningstrafik',
//     values: [
//       'Akut ersättningstrafik',
//       'Fordonstyp',
//       'Linjesträckning',
//       'Linjeutbud och linjesträckningar',
//       'Turtäthet',
//       'Val av trafiklösning, fordonstyp',
//     ]
//   },
//   {
//     name: 'Felanmälan/synpunkt',
//     values: [
//       'Buskage',
//       'Digital skylt',
//       'Felanmälan anläggning',
//       'Felanmälan fordon/fartyg',
//       'Full/trasig papperskorg, sophantering',
//       'Klotter/skadegörelse anläggning',
//       'Klotter/skadegörelse fordon/fartyg',
//       'Skadedjur',
//       'Snöröjning/halkbekämpning',
//       'Städning/lukt anläggning',
//       'Städning/lukt fordon/fartyg',
//       'Teknisk utrustning (ex spärr, biljettautomat m.m.)',
//       'Ventilation, temperatur',
//       'Önskemål/synpunkter om skyltning',
//       'Önskemål/synpunkter om utformning (bänk, hiss, papperskorg etc)'
//     ]
//   },
//   {
//     name: 'Förares körsätt',
//     values: [
//       'Använder mobiltelefon, hörlurar etc.',
//       'Hastighet/obekväm/ryckig körning',
//       'Hänsyn i trafiken'
//     ]
//   },
//   {
//     name: 'Hittegods',
//     values: [
//       'Försvunnet hittegods',
//       'Processer och rutiner rörande hittegods'
//     ]
//   },
//   {
//     name: 'Miljö',
//     values: [
//       'Energianvändning och drivmedel',
//       'Vägutformning/gaturum/övergångsställe/stadsplanering',
//       'Utsläpp och kemikalier'
//     ]
//   },
//   {
//     name: 'Oljud/Buller',
//     values: [
//       'Buller/oljud från fordon/anläggning',
//       'Oljud från underhållsarbete/byggnation'
//     ]
//   },
//   {
//     name: 'Ordning/säkerhet',
//     values: [
//       'Barn i trafiken',
//       'Beredskap för stora händelser, utrymning, skyddsrum m.m.',
//       'Plankning',
//       'Rökning',
//       'Störande beteende',
//       'Tiggeri/försäljare/musikanter',
//       'Trygghetsresurser eller kameror'
//     ]
//   },
//   {
//     name: 'Realtidsinformation',
//     values: [
//       'Automatiska utrop på stn/hpl/brygga',
//       'Prator (fel information eller fungerar inte)',
//       'Realtidsinformation/väntetidsvisning i app',
//       'Realtidsinformation/väntetidsvisning på digital skylt',
//       'Realtidsinformation/väntetidsvisning på webbplats'
//     ]
//   },
//   {
//     name: 'Skada person',
//     values: [
//       'Fall vid färd',
//       'Fordon, övrigt',
//       'Klämd i fordonsdörrar',
//       'Klämd i spärr',
//       'Påkörning',
//       'Övriga egendomsskador (ej fordon)'
//     ]
//   },
//   {
//     name: 'Skada egendom',
//     values: [
//       'Fall vid färd',
//       'Fordon, övrigt',
//       'Klämd i fordonsdörrar',
//       'Påkörning',
//       'Övriga egendomsskador (ej fordon)',
//       'Övriga personskador (ej fordon)'
//     ]
//   },
//   {
//     name: 'Störningsinformation',
//     values: [
//       'Högtalarutrop',
//       'Störningsanslag/-dekaler eller tillfällig skyltning planerad störning',
//       'Störningsinformation från personal',
//       'Störningsinformation i app',
//       'Störningsinformation på digital skylt',
//       'Störningsinformation webbplats'
//     ]
//   },
//   {
//     name: 'Tidtabell',
//     values: [
//       'Avgångs- och ankomsttid',
//       'Bytespunkter och passning mellan trafikslag',
//       'Körtider och restider',
//       'Utformning'
//     ]
//   },
//   {
//     name: 'Tillgänglighet',
//     values: [
//       'Tillgänglighet anläggning',
//       'Tillgänglighet fordon',
//       'Tillgänglighet vid ombyggnation'
//     ]
//   },
//   {
//     name: 'Trafikförvaltningen',
//     values: [
//       'Annan tjänst/komplettering',
//       'Förseningsersättning',
//       'Hyra, upplåta lokal/anläggning/fordon',
//       'Reklam och riktlinjer',
//       'Reseplaneraren, utformning/funktion',
//       'Synpunkt webbplats och app',
//       'Trafikförsörjningsprogram'
//     ]
//   },
//   {
//     name: 'Trafikinformation',
//     values: [
//       'Digital skylt',
//       'Fast skyltning',
//       'Linjekarta/linjeinformation',
//       'Linjenummer/destinationsskylt på fordon/fartyg',
//       'Reseplaneraren, fel',
//       'Stations-, bytes- eller destinationsutrop på fordon/fartyg',
//       'Stations-, bytes- eller destinationsutrop på fordon/fartyg,',
//       'Tidtabeller (anslag)'
//     ]
//   },
//   {
//     name: 'Trafikplanering',
//     values: [
//       'Turtäthet',
//       'Linjeutbud och linjesträckningar',
//       'Val av trafiklösning, fordonstyp',
//       'Vagnordning, husdjursvagnar m.m.'
//     ]
//   },
//   {
//     name: 'Trafikstörning',
//     values: [
//       'Frånåkning',
//       'Störd avgång (utebliven, tidig, sen, ej inväntat anslutning)'
//     ]
//   },
//   {
//     name: 'Trängsel',
//     values: [
//       'Trängsel ombord på fordon/fartyg',
//       'Trängsel/flöden biljetthall/terminal',
//       'Trängsel/flöden plattform/hållplats/brygga',
//       'Trängsel/flöden trappa/rulltrappa'
//     ]
//   }
// ]
