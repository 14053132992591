<div class="cm-customer-info" data-test-id="case-customer">
  <h3>Person info</h3>
  <button mat-flat-button *ngIf="!data" (click)="getCustomerInfo()">
    Get person data
  </button>
  <div class="cm-customer-info__details" *ngIf="data">
    <p>CONTACT</p>
    <div class="cm-customer-info__details__content">
      <div
        class="cm-customer-info__details__position"
        *ngIf="data.customerName"
        data-test-id="case-customer-name">
        <span>Full name:</span>
        <span>{{ data.customerName }}</span>
      </div>
      <div
        class="cm-customer-info__details__position"
        *ngIf="data.customerEmail"
        data-test-id="case-customer-email">
        <span>Email:</span>
        <a href="mailto:{{ data.customerEmail }}">{{ data.customerEmail }}</a>
        <mat-icon
          fontIcon="content_copy"
          class="button-icon"
          (click)="copyToClipboard(data.customerEmail)"></mat-icon>
      </div>
      <div
        class="cm-customer-info__details__position"
        data-test-id="case-customer-phone"
        *ngIf="data.customerPhone">
        <span>Phone:</span>
        <a href="tel:{{ data.customerPhone }}">{{ data.customerPhone }}</a>
        <mat-icon
          fontIcon="content_copy"
          class="button-icon"
          (click)="copyToClipboard(data.customerPhone)"></mat-icon>
      </div>
    </div>

    <div *ngIf="data.requiredCustomerFeedbackAction">
      <div class="cm-customer-info__feedback-required-badge">
        <span>Customer feedback required</span>
      </div>
    </div>
  </div>
</div>
