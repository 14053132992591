import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { AutoScrollDirective } from '@shared/directives/auto-scroll.directive';
import { FocusTextareaDirective } from '@shared/directives/focus-textarea.directive';
import { DescriptionType } from '@shared/enums';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { CaseModel } from '../../case.model';
import { CaseService } from '@shared/services/case.service';
enum visibilityType {
  internal = 0,
  external = 1,
}
@Component({
  selector: 'cm-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent {
  @Input() data!: CaseModel;

  @ViewChild(FocusTextareaDirective) textareaDirective!: FocusTextareaDirective;
  @ViewChild(AutoScrollDirective) autoscrollDirective!: AutoScrollDirective;

  descriptionType = DescriptionType;

  showButtons: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showButtons$ = this.showButtons.asObservable();

  createdCommentId = new BehaviorSubject<{ id: string }>({ id: '' });
  createdCommentId$ = this.createdCommentId.asObservable();

  _isSelected = false;

  get isSelected(): boolean {
    return this._isSelected;
  }

  set isSelected(value: boolean) {
    this._isSelected = value;
  }

  form: FormGroup;

  constructor(
    private fb: NonNullableFormBuilder,
    public caseService: CaseService,
    private route: ActivatedRoute
  ) {
    this.form = this.createForm();
  }

  sendToPartnerChange(event: any) {
    this.isSelected = event;
    this.form.patchValue({ sendToPartner: visibilityType[event ? 1 : 0] });
  }

  saveComment({
    comment,
  }: {
    comment: string;
    sendToPartner: string;
  }) {
    this.caseService
      .addComment(this.route.snapshot.params['id'], comment)
      .subscribe(value => {
        this.createdCommentId.next(value as { id: string });
        this.caseService
          .getCase({ id: this.route.snapshot.params['id'] })
          .subscribe(data => {
            this.data = data.case;
          });
      });
  }

  onTextInput() {
    const { comment } = this.form.value;
    if (comment.length > 0) {
      return;
    }
  }

  submitComment() {
    if (this.form.invalid) {
      return;
    }

    const { comment, sendToPartner } = this.form.value;
    this.saveComment({ comment, sendToPartner });
    this.cancel();
  }

  cancel() {
    this.form.reset();
    this.isSelected = false;
    this.showButtons.next(false);
    this.textareaDirective.changeTextareaDimension();
  }

  handlerHandler() {
    this.showButtons.next(true);
    this.autoscrollDirective.scrollToElement('.cm-comments');
  }

  private createForm(): FormGroup {
    return this.fb.group({
      comment: ['', Validators.required]
    });
  }
}
