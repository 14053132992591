import { Component, Input } from '@angular/core';
import { isDifferenceMoreThan7Days } from '@shared/functions';
import { CaseModel } from '../../case.model';

@Component({
  selector: 'cm-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.scss'],
})
export class CaseDetailsComponent {
  @Input() data!: CaseModel;

  shouldShowTooltip(date: Date | undefined) {
    if (date === undefined) {
      return false;
    }

    const moreThan7Days = isDifferenceMoreThan7Days(date);
    return !moreThan7Days;
  }
}
