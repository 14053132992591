import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CaseService } from '@shared/services';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { StatusBadgeComponent } from '@shared/components/badge/status-badge.component';
import { LabelComponent } from '@shared/components/label/label.component';

@Component({
  selector: 'cm-cases',
  templateUrl: './list-cases.component.html',
  styleUrls: ['./list-cases.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule,
    RouterModule,
    CommonModule,
    MatTableModule,
    StatusBadgeComponent,
    LabelComponent,
  ],
})
export class ListCasesComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public totalRecords = 0;
  pagination = { startAt: 0, maxResults: 20 };
  displayedColumns: string[] = [
    'id',
    'referenceId',
    // 'type',
    'category',
    'additionalInfo',
    'reportedAt',
    'sla',
    'status',
  ];
  public casesListing = new MatTableDataSource(new Array<any>());

  viewDetails(id: string) {
    this.router.navigate(['/case', id]);
  }

  constructor(
    private router: Router,
    private caseService: CaseService
  ) {
    this.caseService
      .getCases({
        page: this.pagination.startAt,
        pageSize: this.pagination.maxResults,
        caseId: null,
        reportedAt: null,
        sortColumn: null,
      })
      .subscribe({
        next: response => {
          this.casesListing = new MatTableDataSource(response.cases);
          this.totalRecords = response.total;
        },
      });
  }

  sortCases(column: string) {
    this.currentSecondarySortColumn = null;
    if (this.currentSortColumn === column) {
      this.currentSortDirection =
        this.currentSortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.currentSortDirection = 'ASC';
    }
    this.currentSortColumn = column;
    this.filterCases();
  }

  currentSortColumn: string | null = 'Status';
  currentSecondarySortColumn: string | null = 'Created';
  currentSortDirection: 'ASC' | 'DESC' = 'ASC';
  currentSecondarySortDirection: 'ASC' | 'DESC' = 'DESC';

  public form: FormGroup = new FormGroup({
    caseId: new FormControl(''),
    referenceId: new FormControl(''),
    type: new FormControl(''),
    district: new FormControl(''),
    status: new FormControl(''),
    reportedAt: new FormControl(),
  });

  filterCases() {
    const filterValues = this.form.value;
    this.loadCases({
      ...filterValues,
      sortColumn: this.currentSortColumn,
      sortDirection: this.currentSortDirection,
      secondarySortColumn: this.currentSecondarySortColumn,
      secondarySortDirection: this.currentSecondarySortDirection,
    });
  }

  loadCases(filters: any = {}) {
    this.caseService
      .getCases({
        page: this.pagination.startAt,
        pageSize: this.pagination.maxResults,
        ...filters,
      })
      .subscribe({
        next: response => {
          this.casesListing = new MatTableDataSource(response.cases);
          this.totalRecords = response.total;
        },
      });
  }

  pageChangeEvent(event: { pageIndex: number; pageSize: number }) {
    this.caseService
      .getCases({
        page: event.pageIndex,
        pageSize: event.pageSize,
        ...this.form.value,
      })
      .subscribe({
        next: response => {
          this.casesListing = new MatTableDataSource(response.cases);
          this.totalRecords = response.total;
        },
      });
  }
}
