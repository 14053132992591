import * as i0 from '@angular/core';
import { Injectable, Inject, Component, NgModule } from '@angular/core';
import { Subject, BehaviorSubject, Observable, fromEvent, catchError, of, filter, merge } from 'rxjs';
import * as i2 from '@angular/router';
import { NavigationEnd } from '@angular/router';
import * as i1$1 from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as i1 from '@ngx-translate/core';
import { LogLevel, HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import * as i1$2 from '@angular/service-worker';
import moment from 'moment';
import { map } from 'rxjs/operators';
class SsoConfiguration {
  constructor(env) {
    this.env = env;
  }
  clientId() {
    return this.env.clientId;
  }
  wellKnownConfigUrl() {
    const url = `${this.env.authServerUrl}${this.env.authWellKnown}`;
    return url;
  }
  accessForRead() {
    return this.env.accessForRead;
  }
  accessForWrite() {
    return this.env.accessForWrite;
  }
  accessForAdminister() {
    return this.env.accessForAdminister;
  }
  accessSpecificForApp() {
    return this.env.accessSpecificForApp;
  }
  static {
    this.ɵfac = function SsoConfiguration_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SsoConfiguration)(i0.ɵɵinject('env'));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SsoConfiguration,
      factory: SsoConfiguration.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SsoConfiguration, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: ['env']
      }]
    }];
  }, null);
})();
var HttpMethod;
(function (HttpMethod) {
  HttpMethod["GET"] = "GET";
  HttpMethod["POST"] = "POST";
  HttpMethod["PUT"] = "PUT";
  HttpMethod["DELETE"] = "DELETE";
  HttpMethod["PATCH"] = "PATCH";
})(HttpMethod || (HttpMethod = {}));
const YYYY_MM_DD_FORMAT = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
class UrlParameters {
  constructor(host, parameters) {
    this.host = host;
    this.parameters = parameters;
  }
}
var localStorageEnum;
(function (localStorageEnum) {
  localStorageEnum["selectedDropdownsItemsIds"] = "selectedDropdownsItemsId";
  localStorageEnum["customLogin"] = "customLogin";
  localStorageEnum["selectedDistrictIds"] = "selectedDistrictIds";
})(localStorageEnum || (localStorageEnum = {}));
class Header {
  constructor(name, isAccentTheme = true, isVisibleButton = true, isVisibleDropdownItems = true, useMainLogo = false, isMultiDistrict = false) {
    this.name = name;
    this.isAccentTheme = isAccentTheme;
    this.isVisibleButton = isVisibleButton;
    this.isVisibleDropdownItems = isVisibleDropdownItems;
    this.useMainLogo = useMainLogo;
    this.isMultiDistrict = isMultiDistrict;
    this.button = new Subject();
    this.logoButton = new Subject();
    this.setDropdownItems = new Subject();
    this.setButtonName = new Subject();
    this.setDefaultUserDistrict = new Subject();
    this.setSelectedDropdownItems = new Subject();
    this.dropdownItems = new Array();
    this.selectedDropdownItems = new Array();
    this.selectedDropdownItemsChanged = new BehaviorSubject([]);
    this.nameSubject = new Subject();
    this.setDropdownItems.subscribe(items => {
      this.dropdownItems = items;
    });
    this.setButtonName.subscribe(buttonName => {
      this.buttonName = buttonName;
    });
    this.setDefaultUserDistrict.subscribe(defaultUserDistrict => {
      this.defaultUserDistrict = defaultUserDistrict;
      if (this.selectedDropdownItems.length <= 0) {
        this.selectedDropdownItemsChanged.next([defaultUserDistrict]);
      }
    });
    this.nameSubject.subscribe(newName => {
      this.name = newName;
    });
    this.setSelectedDropdownItems.subscribe(dropDownItemsToSelect => {
      const foundSelectedItems = new Array();
      if (!dropDownItemsToSelect) {
        throw new Error('Dropdown items to select is null');
      }
      if (!isMultiDistrict && dropDownItemsToSelect && dropDownItemsToSelect.length > 1) {
        foundSelectedItems.push(dropDownItemsToSelect[0]);
      } else {
        this.setItemsToSelect(dropDownItemsToSelect, foundSelectedItems);
      }
      this.selectedDropdownItemsChanged.next(foundSelectedItems);
    });
    this.selectedDropdownItemsChanged.subscribe(dropDownItems => {
      if (dropDownItems && dropDownItems.length > 0) {
        this.selectedDropdownItems = dropDownItems;
        localStorage.setItem(localStorageEnum.selectedDropdownsItemsIds, JSON.stringify(dropDownItems));
      }
    });
  }
  setItemsToSelect(dropDownItemsToSelect, foundSelectedItems) {
    dropDownItemsToSelect.forEach(item => {
      const foundItem = this.dropdownItems.find(d => d.id === item.id);
      if (foundItem) {
        foundSelectedItems.push(foundItem);
      }
    });
  }
}
class HeaderDropdownItem {
  constructor(id, icon, name) {
    this.id = id;
    this.icon = icon;
    this.name = name;
  }
}
class GeoPosition {
  constructor(latitude, longitude, accuracy, timestamp) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.accuracy = accuracy;
    this.timestamp = timestamp;
  }
  static of(position) {
    return new GeoPosition(position.coords.latitude, position.coords.longitude, position.coords.accuracy, position.timestamp);
  }
}
class MenuItem {
  constructor(icon, translationKey, clickSubject, route, selectionRoutes = [], visible = true, disabled = false, orderNo = 9999) {
    this.icon = icon;
    this.translationKey = translationKey;
    this.clickSubject = clickSubject;
    this.route = route;
    this.selectionRoutes = selectionRoutes;
    this.visible = visible;
    this.disabled = disabled;
    this.orderNo = orderNo;
  }
}
class Navigation {
  constructor(buttons = [], navigationItems = [], closeAfterClick = true, defaultOrder = true) {
    this.closeAfterClick = closeAfterClick;
    this.defaultOrder = defaultOrder;
    this.mobileMenuVisible = false;
    this.mobileMenuVisibleSubject = new Subject();
    this.addNavigationItem = new Subject();
    this.addButton = new Subject();
    this.inputNavigationItems = navigationItems;
    this.inputButtons = buttons;
    this.sortMenuItems(this.inputNavigationItems);
    this.addNavigationItem.subscribe(item => {
      this.inputNavigationItems.push(item);
      if (!defaultOrder) {
        this.sortMenuItems(this.inputNavigationItems);
      }
    });
    this.addButton.subscribe(item => {
      this.inputButtons.push(item);
    });
  }
  navigationItems() {
    return this.inputNavigationItems;
  }
  buttons() {
    return this.inputButtons;
  }
  sortMenuItems(menuItems) {
    menuItems.sort((a, b) => a.orderNo - b.orderNo);
  }
}
class Request {
  constructor(method, url) {
    this._method = method;
    this._url = url ? url : '';
  }
  set url(value) {
    this._url = value;
  }
  set headers(value) {
    this._headers = value;
  }
  set mapper(value) {
    this._mapper = value;
  }
  set callback(value) {
    this._callback = value;
  }
  get headers() {
    return this._headers || new Headers();
  }
  get url() {
    return this._url;
  }
  get method() {
    return this._method;
  }
  get mapper() {
    return this._mapper;
  }
  get callback() {
    return this._callback;
  }
}
class RequestWithBody extends Request {
  set body(value) {
    this._body = value;
  }
  get body() {
    return this._body;
  }
}
class HttpOptions {}
class PushNotification {}
class ConnectionManager {
  constructor(connectionName, hub, configuration, isRunning = false) {
    this.subjectMap = new Map();
    this.connectionName = connectionName;
    this.hub = hub;
    this.isRunning = isRunning;
    this.configuration = configuration;
  }
  startConnection() {
    return this.hub.start().then(() => {
      this.isRunning = true;
      return this.isRunning;
    }).catch(err => console.log('Error while starting connection: ' + err));
  }
  stopConnection() {
    return this.hub.stop().then(() => {
      this.isRunning = false;
      return this.isRunning;
    });
  }
  onNewMessage(eventName, callback) {
    const subject = new Subject();
    this.subjectMap.set(eventName, subject);
    this.hub.on(eventName, message => {
      subject.next(message);
      if (callback) {
        callback(message);
      }
    });
  }
  sendOperationMessage(eventName, op) {
    this.hub.invoke(eventName, op.operation, op.field, op.value);
  }
  sendGeneralMessage(eventName, message) {
    this.hub.invoke(eventName, message);
  }
}
var OPERATION;
(function (OPERATION) {
  OPERATION["UNKNOWN"] = "UNKNOWN";
  OPERATION["REPLACE_ALL"] = "REPLACE_ALL";
  OPERATION["REPLACE"] = "REPLACE";
  OPERATION["REMOVE"] = "REMOVE";
  OPERATION["ADD"] = "ADD";
})(OPERATION || (OPERATION = {}));
var SpecialCategoryType;
(function (SpecialCategoryType) {
  SpecialCategoryType["MESSAGES"] = "messages";
  SpecialCategoryType["UNREAD"] = "unread";
  SpecialCategoryType["FAVOURITES"] = "favourites";
})(SpecialCategoryType || (SpecialCategoryType = {}));
class Category {
  constructor(processTag) {
    this.children = new Array();
    this.documents = new Array();
    this.isCategoryNameVisible = false;
    this.isExpanded = false;
    this.icoPath = 'ico_VLS.svg';
    this.specialCategory = null;
    if (processTag) {
      this.processTag = processTag;
    }
  }
}
class Document {}
class ProcessTag {}
var authSessionDataEnum;
(function (authSessionDataEnum) {
  authSessionDataEnum["accessToken"] = "authSessionData_accessToken";
  authSessionDataEnum["id_token"] = "authSessionData_idToken";
  authSessionDataEnum["accessTokenType"] = "authSessionData_accessTokenType";
  authSessionDataEnum["tokenExpiresAt"] = "authSessionData_tokenExpiresAt";
  authSessionDataEnum["ssoUser"] = "authSessionData_ssoUser";
})(authSessionDataEnum || (authSessionDataEnum = {}));
class SsoUser {
  constructor() {}
  set(user) {
    if (user) {
      this.access = user.access;
      this.roledistricts = user.roledistricts;
      this.firstname = user.firstname;
      this.lastname = user.lastname;
      this.mail = user.mail;
      this.employeenumber = user.employeenumber;
      this.defaultdistrictid = user.defaultdistrictid;
      this.phonenumber = user.phonenumber;
      this.username = user.username;
      this.nbf = user.nbf;
      this.exp = user.exp;
      this.iss = user.iss;
      this.aud = user.aud;
      this.client_id = user.client_id;
      this.nameidentifier = user.nameidentifier;
      this.auth_time = user.auth_time;
      this.identityprovider = user.identityprovider;
      this.scope = user.scope;
      this.authnmethodsreferences = user.authnmethodsreferences;
      this.role = typeof user.role === 'string' ? [user.role] : user.role;
      this.sub = user.sub;
      this.fullname = this.firstname + ' ' + this.lastname;
      this.id = this.sub;
    }
  }
}
class SsoHelper {
  constructor() {}
  static createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0;
      // tslint:disable-next-line:no-bitwise
      const v = c === 'x' ? r : (r && 0x3) | 0x8;
      return v.toString(16);
    });
  }
  static getSsoProperUri(page, host = window.location.host) {
    let locationStrategy = '/';
    return window.location.protocol.concat('//', host, locationStrategy, page);
  }
  static decodeJwtToken(jwt_token) {
    const result = new SsoUser();
    if (jwt_token) {
      const base64Url = jwt_token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const userJson = JSON.parse(this.b64DecodeUnicode(base64));
      result.set(userJson);
    }
    return result;
  }
  static b64DecodeUnicode(jwt_token) {
    return decodeURIComponent(Array.prototype.map.call(atob(jwt_token), function (char) {
      return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
  static hasUserAccess(currentUser, districtID, accessesCollection) {
    if (!currentUser) {
      return false;
    }
    let result = false;
    for (let i = 0; i < accessesCollection.length; i++) {
      if (currentUser.access.find(access => access.a === accessesCollection[i] && (-1 !== districtID ? -1 < access.d.indexOf(0 === districtID ? currentUser.defaultdistrictid : districtID) : true))) {
        result = true;
        break;
      }
    }
    return result;
  }
  static listUserDistricts(currentUser, accessNames) {
    if (!currentUser) {
      return [];
    }
    let result = Array();
    currentUser.access.forEach(access => {
      if (accessNames.includes(access.a)) {
        result = result.concat([...new Set(access.d)]);
      }
    });
    return [...new Set(result)];
  }
}
class UserService {
  constructor(ssoConfiguration) {
    this.ssoConfiguration = ssoConfiguration;
    this.currentUserSubject = new Subject();
    this.userSetChangedSubject = new Subject();
    this.currentUserSubject.subscribe(item => {
      this._currentUser = item;
      this.userSetChangedSubject.next(true);
    });
  }
  get currentUser() {
    if (!this._currentUser) {
      try {
        const ssoUser = localStorage.getItem(authSessionDataEnum.ssoUser);
        if (ssoUser) {
          this.currentUserSubject.next(JSON.parse(ssoUser));
        }
      } catch {}
    }
    return this._currentUser;
  }
  /** Returns true if user is loged.
   * - If districtId is not set or is set to "-1" - checks whether the user has any level of access regardless of district.
   * - If districtId is not set or is set to "0" - checks whether the user has any level of access in default user district.
   * - In other way - checks with regard to the district
   */
  isCurrentUserSet(districtId = 0) {
    return (this.currentUser ? true : false) && this.canUserRead(districtId) || this.canUserWrite(districtId) || this.canUserAdminister(districtId);
  }
  /** Checks if user has "read" access level.
   * - If districtId is not set or is set to "-1" - checks whether the user has "read" level of access regardless of district.
   * - If districtId is not set or is set to "0" - checks whether the user has "read" level of access in default user district.
   * - In other way - checks with regard to the district
   */
  canUserRead(districtId = 0) {
    const accessesCollection = this.ssoConfiguration.accessForRead();
    return SsoHelper.hasUserAccess(this.currentUser, districtId === 0 && this.currentUser ? this.currentUser.defaultdistrictid : districtId, accessesCollection) || this.canUserWrite(districtId) || this.canUserAdminister(districtId);
  }
  /** Checks if user has "write" access level.
   * - If districtId is not set or is set to "-1" - checks whether the user has "write" level of access regardless of district.
   * - If districtId is not set or is set to "0" - checks whether the user has "write" level of access in default user district.
   * - In other way - checks with regard to the district
   */
  canUserWrite(districtId = 0) {
    const accessesCollection = this.ssoConfiguration.accessForWrite();
    return SsoHelper.hasUserAccess(this.currentUser, districtId === 0 && this.currentUser ? this.currentUser.defaultdistrictid : districtId, accessesCollection) || this.canUserAdminister(districtId);
  }
  /** Checks if user has "administer" access level.
   * - If districtId is not set or is set to "-1" - checks whether the user has "administer" level of access regardless of district.
   * - If districtId is not set or is set to "0" - checks whether the user has "administer" level of access in default user district.
   * - In other way - checks with regard to the district
   */
  canUserAdminister(districtId = 0) {
    const accessesCollection = this.ssoConfiguration.accessForAdminister();
    return SsoHelper.hasUserAccess(this.currentUser, districtId === 0 && this.currentUser ? this.currentUser.defaultdistrictid : districtId, accessesCollection);
  }
  // TODO - LA - for future use
  /** Checks whether the user has the access level specified, specific to the application. */
  // private haveUserAccessLevel(accessName: string, districtId = -1): boolean {
  // 	let result = false;
  // 	const accessesSpecificForAppCollection = this.ssoConfiguration.accessSpecificForApp();
  // 	const accessesSpecificForApp = accessesSpecificForAppCollection.find(item => item.accesLevel === accessName);
  // 	if (accessesSpecificForApp) {
  // 		result = SsoHelper.hasUserAccess(
  // 			this.currentUser,
  // 			this.currentUser.defaultdistrictid,
  // 			accessesSpecificForApp.accessColection
  // 		);
  // 	}
  // 	return result;
  // }
  /** Checks whether the user has the given permission.
   *   @deprecated - please use method without haveUserAccessRightsInDistrict
   */
  haveUserAccessRights(accessesCollection, districtId = 0) {
    return SsoHelper.hasUserAccess(this.currentUser, districtId, accessesCollection);
  }
  haveUserAccessRightsInDistrict(accessesCollection, districtId) {
    return SsoHelper.hasUserAccess(this.currentUser, districtId, accessesCollection);
  }
  /** lists the districts available to the user on the basis of available access */
  listsUserDistricts(accessNames) {
    return SsoHelper.listUserDistricts(this.currentUser, accessNames);
  }
  /** lists the districts available to the user on the basis of all available accesses*/
  listsUserDistrictsForAllUserAccessess() {
    return SsoHelper.listUserDistricts(this.currentUser, this.ssoConfiguration.accessForRead().concat(this.ssoConfiguration.accessForWrite()).concat(this.ssoConfiguration.accessForAdminister()));
  }
  /** Returns access token. */
  get getToken() {
    return localStorage.getItem(authSessionDataEnum.accessToken);
  }
  /** Returns id token. */
  get getIdToken() {
    return localStorage.getItem(authSessionDataEnum.id_token);
  }
  static {
    this.ɵfac = function UserService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserService)(i0.ɵɵinject(SsoConfiguration));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserService,
      factory: UserService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: SsoConfiguration
    }];
  }, null);
})();
class SettingsService {
  constructor(userService) {
    this.userService = userService;
  }
  get selectedDistrictIds() {
    const savedVal = localStorage.getItem(localStorageEnum.selectedDistrictIds);
    return savedVal ? JSON.parse(savedVal) : [this.userService.currentUser?.defaultdistrictid];
  }
  set selectedDistrictIds(ids) {
    localStorage.setItem(localStorageEnum.selectedDistrictIds, JSON.stringify(ids));
  }
  static {
    this.ɵfac = function SettingsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SettingsService)(i0.ɵɵinject(UserService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SettingsService,
      factory: SettingsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SettingsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: UserService
    }];
  }, null);
})();
class AuthGuardService {
  constructor(router, userService, settingsService) {
    this.router = router;
    this.userService = userService;
    this.settingsService = settingsService;
  }
  canLoad(route) {
    if (this.validateAccessByToken()) {
      if (!route || !route.data || !route.data.accesses) {
        return true;
      } else if (this.validateAccessByAccess(route.data.accesses)) {
        return true;
      } else {
        this.router.navigate(['/unauthorized'], {});
      }
    } else {
      const url = `/${route.path}`;
      this.router.navigate(['/login'], {
        queryParams: {
          redirectTo: url
        }
      });
    }
    return false;
  }
  canActivate(route, state) {
    if (this.validateAccessByToken()) {
      if (!route || !route.data || !route.data.accesses) {
        return true;
      } else if (this.validateAccessByAccess(route.data.accesses)) {
        return true;
      } else {
        this.router.navigate(['/unauthorized'], {});
      }
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          redirectTo: state.url
        }
      });
    }
    return false;
  }
  canActivateChild(route, state) {
    return this.canActivate(route, state);
  }
  validateAccessByToken() {
    const accessToken = localStorage.getItem(authSessionDataEnum.accessToken);
    const tokenExpiresAt = localStorage.getItem(authSessionDataEnum.tokenExpiresAt);
    if (accessToken && tokenExpiresAt && new Date(tokenExpiresAt) > new Date()) {
      return true;
    }
    return false;
  }
  validateAccessByAccess(accesses) {
    return this.checkAccess(accesses, 'accessForAdminister') || this.checkAccess(accesses, 'accessForWrite') || this.checkAccess(accesses, 'accessForRead');
  }
  checkAccess(accesses, access) {
    if (accesses.hasOwnProperty(access)) {
      return this.userService.haveUserAccessRightsInDistrict(accesses[access], this.settingsService.selectedDistrictIds[0]);
    }
    return true;
  }
  static {
    this.ɵfac = function AuthGuardService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AuthGuardService)(i0.ɵɵinject(i2.Router), i0.ɵɵinject(UserService), i0.ɵɵinject(SettingsService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthGuardService,
      factory: AuthGuardService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthGuardService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i2.Router
    }, {
      type: UserService
    }, {
      type: SettingsService
    }];
  }, null);
})();
class SsoTokenParameters {
  constructor(state, id_token, access_token, token_type, expires_in, scope) {
    this.id_token = '';
    this.access_token = '';
    this.token_type = '';
    this.state = state;
    this.access_token = access_token;
    this.id_token = id_token;
    this.token_type = token_type;
    this.expires_in = expires_in;
    this.scope = scope;
  }
}
class L10nService {
  constructor(translation, defaultTranslation, translateService) {
    this.translation = translation;
    this.defaultTranslation = defaultTranslation;
    this.translateService = translateService;
    this.translationSetup = new Subject();
    this.currentTranslation = 'en';
    if (window.navigator.language.split('-')[0] === 'sv') {
      this.setupLanguage('sv', translation);
    } else {
      this.setupLanguage('en', defaultTranslation);
    }
  }
  translate(currentSection, key) {
    const result = new Promise(resolve => {
      if (currentSection.length > 0 && key.length > 0) {
        if (this.translateService.currentLang && 0 < this.translateService.currentLang.length) {
          this.translateService.get(currentSection + '.' + key).subscribe(translation => {
            resolve(translation);
          });
        } else {
          this.translateService.onTranslationChange.subscribe(_ => this.translateService.get(currentSection + '.' + key).subscribe(translation => {
            resolve(translation);
          }));
        }
      } else {
        resolve(currentSection + '.' + key);
      }
    });
    return result;
  }
  changeLanguage(lang) {
    switch (lang) {
      case 'en':
        this.currentTranslation = this.defaultTranslation;
        this.translateService.use(lang);
        break;
      case 'sv':
        this.currentTranslation = this.translation;
        this.translateService.use(lang);
        break;
      default:
        this.currentTranslation = this.defaultTranslation;
        this.translateService.use('en');
        break;
    }
    this.translateService.setTranslation(lang, this.currentTranslation);
    this.translationSetup.next(true);
  }
  setupLanguage(lang, translation) {
    this.translateService.use(lang);
    this.translateService.setTranslation(lang, translation);
    this.translationSetup.next(true);
  }
  getCurrentLang() {
    return this.translateService.store.currentLang;
  }
  static {
    this.ɵfac = function L10nService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nService)(i0.ɵɵinject('translation'), i0.ɵɵinject('defaultTranslation'), i0.ɵɵinject(i1.TranslateService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nService,
      factory: L10nService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: ['translation']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: ['defaultTranslation']
      }]
    }, {
      type: i1.TranslateService
    }];
  }, null);
})();
class LoginService {
  constructor(http, ssoConfiguration, userService, l10nService) {
    this.http = http;
    this.ssoConfiguration = ssoConfiguration;
    this.userService = userService;
    this.l10nService = l10nService;
    this.pinErrorMessage = new Subject();
    this.customIdErrorMessage = new Subject();
    this.getWellKnownConfiguration().subscribe(wellKnown => {
      this.wellKnownConfiguration = wellKnown;
      this.setExchangeToken(this.wellKnownConfiguration);
    });
    this.setLogoutTimeout();
  }
  login(urlParameters) {
    this.ssoAuthorizationLink(urlParameters).then(val => {
      console.log('sso:' + val);
      window.location.href = val;
    }, err => this.errorMessage = err).catch(err => {
      console.log(err);
      this.errorMessage = err;
    });
  }
  loginWithUrl(login, password) {
    return new Promise((resolve, reject) => {
      this.waitForWellKnownConfig().then(() => {
        this.loginWithUrlProceed(login, password, this.wellKnownConfiguration).then(result => resolve(result)).catch(err => reject(err));
      }).catch(error => {
        reject(error);
      });
    });
  }
  loginWithPin(credentials) {
    return new Promise((resolve, reject) => {
      this.waitForWellKnownConfig().then(() => {
        this.loginWithPinProceed(credentials, this.wellKnownConfiguration).then(_ => resolve()).catch(_ => reject());
      });
    });
  }
  loginWithCustomId(customId) {
    return new Promise((resolve, reject) => {
      this.waitForWellKnownConfig().then(() => {
        this.loginWithCustomIdProceed(customId, this.wellKnownConfiguration).then(_ => resolve()).catch(_ => reject());
      });
    });
  }
  waitForWellKnownConfig() {
    return new Promise(resolve => {
      if (this.wellKnownConfiguration) {
        resolve(this.wellKnownConfiguration);
      } else {
        this.getWellKnownConfiguration().subscribe(wellKnown => {
          this.wellKnownConfiguration = wellKnown;
          resolve(this.wellKnownConfiguration);
        });
      }
    });
  }
  logout() {
    this.ssoLogoutLink().then(val => {
      const token = localStorage.getItem(authSessionDataEnum.id_token);
      const isCustomLogin = localStorage.getItem(localStorageEnum.customLogin);
      localStorage.removeItem(authSessionDataEnum.accessToken);
      localStorage.removeItem(authSessionDataEnum.id_token);
      localStorage.removeItem(authSessionDataEnum.ssoUser);
      localStorage.removeItem(localStorageEnum.customLogin);
      localStorage.removeItem(localStorageEnum.selectedDropdownsItemsIds);
      this.userService.currentUserSubject.next(new SsoUser());
      this.logoutProceed(isCustomLogin != null, val, token);
    }, err => this.errorMessage = err).catch(err => this.errorMessage = err);
  }
  logoutProceed(isCustomLogin, baseUrl, token) {
    if (isCustomLogin) {
      localStorage.removeItem(authSessionDataEnum.tokenExpiresAt);
      clearTimeout(this.timeout);
      window.location.href = window.location.origin;
    } else {
      const url = new URL(baseUrl);
      const params = url.searchParams;
      params.append('id_token_hint', token ?? '');
      params.append('post_logout_redirect_uri', window.location.origin);
      window.location.href = url.toString();
    }
  }
  authenticateCallback(url) {
    this.prepareSsoTokenParameters(url);
    if (this.isValidSsoTokenParameters() && this.isCurrentTokenState()) {
      localStorage.setItem(authSessionDataEnum.id_token, this.ssoTokenParameters.id_token);
      this.getExchangeToken();
    } else {
      this.l10nService.translate('SSO', 'TOKEN_VALIDATION_ERROR').then(message => {
        this.errorMessage = message;
      });
      this.exchangeTokenFailedLogout();
    }
  }
  authenticateExternal(url) {
    this.prepareSsoTokenParameters(url);
    if (this.isValidSsoTokenParametersForExternal()) {
      this.getExchangeToken();
    } else {
      this.l10nService.translate('SSO', 'TOKEN_VALIDATION_ERROR').then(message => {
        this.errorMessage = message;
      });
      this.exchangeTokenFailedLogout();
    }
  }
  authenticateExternalWithRouteRedirection(url, routePath) {
    this.prepareSsoTokenParameters(url);
    if (this.isValidSsoTokenParametersForExternal()) {
      this.ssoExchangeToken(this.ssoTokenParameters.access_token, this.ssoTokenParameters.token_type).then(val => {
        window.location.href = routePath ? window.location.origin + '/' + routePath : window.location.origin;
      });
    } else {
      this.l10nService.translate('SSO', 'TOKEN_VALIDATION_ERROR').then(message => {
        this.errorMessage = message;
      });
      this.exchangeTokenFailedLogout();
    }
  }
  loginWithPinProceed(credentials, wellKnownConfiguration) {
    const body = this.prepareAuthorizationPinBodyParams(credentials);
    return this.customLoginGetExchangeToken(body, wellKnownConfiguration);
  }
  loginWithUrlProceed(login, password, wellKnownConfiguration) {
    const body = this.prepareAuthorizationUrlBodyParams(login, password);
    return this.urlLoginGetExchangetoken(body, wellKnownConfiguration);
  }
  loginWithCustomIdProceed(customId, wellKnownConfiguration) {
    const body = this.prepareAuthorizationCustomIdBodyParams(customId);
    return this.customLoginGetExchangeToken(body, wellKnownConfiguration, true);
  }
  setLogoutTimeout() {
    const isCustomLogin = localStorage.getItem(localStorageEnum.customLogin);
    if (isCustomLogin) {
      const tokenExpiration = localStorage.getItem(authSessionDataEnum.tokenExpiresAt);
      if (tokenExpiration) {
        const expirationDate = new Date(tokenExpiration);
        const expireIn = expirationDate.getTime() - new Date().getTime();
        this.timeout = window.setTimeout(() => {
          this.logout();
        }, expireIn);
      }
    }
  }
  customLoginGetExchangeToken(body, wellKnownConfiguration, customIdLogin = false) {
    return new Promise((resolve, reject) => {
      this.http.post(wellKnownConfiguration.token_endpoint, body, {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      }).subscribe(result => {
        this.prepareParameters(result, body);
        localStorage.setItem(localStorageEnum.customLogin, 'true');
        localStorage.setItem(authSessionDataEnum.id_token, this.ssoTokenParameters.access_token);
        this.getExchangeToken(() => resolve(), () => reject());
        this.setLogoutTimeout();
      }, err => {
        if (customIdLogin) {
          this.customIdErrorMessage.next(err);
        } else {
          this.pinErrorMessage.next(err);
        }
        reject();
      });
    });
  }
  urlLoginGetExchangetoken(body, wellKnownConfiguration) {
    return new Promise((resolve, reject) => {
      this.http.post(wellKnownConfiguration.token_endpoint, body, {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      }).subscribe(result => {
        this.prepareParameters(result, body);
        localStorage.setItem(localStorageEnum.customLogin, 'true');
        localStorage.setItem(authSessionDataEnum.id_token, this.ssoTokenParameters.access_token);
        this.getExchangeTokenForUrl(result => {
          resolve(result);
        }, error => {
          reject(error);
        });
        this.setLogoutTimeout();
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  getExchangeTokenForUrl(callback, errorCallback) {
    this.ssoExchangeToken(this.ssoTokenParameters.access_token, this.ssoTokenParameters.token_type).then(val => {
      if (callback) {
        callback(val);
      }
    }).catch(err => {
      console.log(err);
      this.errorMessage = err.message;
      this.exchangeTokenFailedLogout();
      if (errorCallback) {
        errorCallback(err);
      }
    });
  }
  getExchangeToken(callback, errorCallback) {
    this.ssoExchangeToken(this.ssoTokenParameters.access_token, this.ssoTokenParameters.token_type).then(val => {
      if (callback) {
        callback();
      }
      const loginReturnPath = localStorage.getItem('loginReturnPath');
      if (loginReturnPath) {
        window.location.href = loginReturnPath;
      } else {
        const params = new URLSearchParams(window.location.search);
        const redirectToParam = params.get('redirectTo');
        window.location.href = redirectToParam ? window.location.origin + '#' + redirectToParam : window.location.origin + '#';
      }
    }).catch(err => {
      console.log(err);
      this.errorMessage = err.message;
      this.exchangeTokenFailedLogout();
      if (errorCallback) {
        errorCallback();
      }
    });
  }
  exchangeTokenFailedLogout() {
    localStorage.removeItem(authSessionDataEnum.accessToken);
    localStorage.removeItem(authSessionDataEnum.id_token);
    localStorage.removeItem(authSessionDataEnum.ssoUser);
    this.userService.currentUserSubject.next(new SsoUser());
    window.location.href = window.location.origin;
  }
  isCurrentTokenState() {
    return this.ssoTokenParameters.state === localStorage.getItem('ssoState');
  }
  setExchangeToken(wellKnown) {
    wellKnown.exchangeToken_endpoint = wellKnown.issuer + '/api/token/exchange';
  }
  getWellKnownConfiguration() {
    return this.http.get(this.ssoConfiguration.wellKnownConfigUrl());
  }
  ssoAuthorizationLink(urlParameters) {
    const result = new Promise((resolve, reject) => {
      if (this.wellKnownConfiguration) {
        resolve(this.getSsoAuthorization(this.wellKnownConfiguration, urlParameters));
      } else {
        this.getWellKnownConfiguration().subscribe(wellKnown => {
          this.wellKnownConfiguration = wellKnown;
          this.setExchangeToken(this.wellKnownConfiguration);
          resolve(this.getSsoAuthorization(this.wellKnownConfiguration, urlParameters));
        });
      }
    });
    return result;
  }
  getSsoAuthorization(wellKnown, urlParameters) {
    const currentUrl = urlParameters ? urlParameters.host : window.location.href;
    const ssoCallbackUri = SsoHelper.getSsoProperUri('sso_callback', window.location.host);
    if (ssoCallbackUri !== currentUrl) {
      let params = '';
      let redirectToParam = '';
      if (urlParameters && urlParameters.parameters) {
        const parameters = urlParameters.parameters;
        Object.keys(parameters).map(key => {
          return {
            key: key,
            value: parameters[key]
          };
        }).forEach(item => {
          if (item.key = 'redirectTo') {
            redirectToParam = item.value;
          }
          params = params + '&' + item.key + '=' + item.value;
        });
        redirectToParam = redirectToParam.substr(redirectToParam.indexOf('/') === 0 ? 1 : 0);
        params = encodeURI(params.substr(1));
      }
      localStorage.setItem('loginReturnPath', urlParameters ? SsoHelper.getSsoProperUri(redirectToParam, currentUrl) : currentUrl);
      const ssoState = SsoHelper.createGuid();
      localStorage.setItem('ssoState', ssoState);
      return this.prepareAuthorizationUri(ssoCallbackUri, ssoState, wellKnown);
    }
    return ssoCallbackUri;
  }
  prepareAuthorizationUri(ssoCallbackUri, ssoState, wellKnown) {
    const url = new URL(wellKnown.authorization_endpoint);
    const params = url.searchParams;
    params.append('client_id', this.ssoConfiguration.clientId());
    params.append('response_type', 'id_token token');
    params.append('redirect_uri', ssoCallbackUri);
    params.append('scope', 'openid user');
    params.append('state', ssoState);
    params.append('nonce', SsoHelper.createGuid());
    params.append('require_consent', 'false');
    window.location.href = url.toString();
    console.log('url:   ' + url.toString());
    return url.toString();
  }
  prepareAuthorizationPinBodyParams(pinCredentials) {
    const body = new HttpParams().set('client_id', this.ssoConfiguration.clientId()).set('grant_type', 'pin').set('identifier', pinCredentials.employeeNumber).set('scope', 'user').set('client_secret', 'VerySharedSecret').set('pincode', pinCredentials.pin);
    return body;
  }
  prepareAuthorizationUrlBodyParams(login, password) {
    const body = new HttpParams().set('client_id', this.ssoConfiguration.clientId()).set('grant_type', 'password').set('username', login).set('password', password);
    return body;
  }
  prepareAuthorizationCustomIdBodyParams(customId) {
    const body = new HttpParams().set('client_id', this.ssoConfiguration.clientId()).set('grant_type', 'custom_id').set('customid', customId).set('scope', 'user').set('type', 'SSN').set('client_secret', 'VerySharedSecret');
    return body;
  }
  ssoLogoutLink() {
    const result = new Promise((resolve, reject) => {
      if (this.wellKnownConfiguration) {
        resolve(this.getSsoLogout(this.wellKnownConfiguration));
      } else {
        this.getWellKnownConfiguration().subscribe(wellKnown => {
          this.wellKnownConfiguration = wellKnown;
          this.setExchangeToken(this.wellKnownConfiguration);
          resolve(this.getSsoLogout(this.wellKnownConfiguration));
        });
      }
    });
    return result;
  }
  getSsoLogout(wellKnown) {
    return wellKnown.end_session_endpoint;
  }
  isValidSsoTokenParameters() {
    return null != this.ssoTokenParameters.state && null != this.ssoTokenParameters.access_token && null != this.ssoTokenParameters.token_type && null != this.ssoTokenParameters.expires_in && null != this.ssoTokenParameters.scope;
  }
  isValidSsoTokenParametersForExternal() {
    return null != this.ssoTokenParameters.access_token && null != this.ssoTokenParameters.token_type && null != this.ssoTokenParameters.expires_in;
  }
  prepareSsoTokenParameters(url) {
    const params = new URLSearchParams(url.split('#')[1]);
    this.ssoTokenParameters = new SsoTokenParameters(params.get('state'), params.get('id_token'), params.get('access_token'), params.get('token_type'), params.get('expires_in'), params.get('scope'));
  }
  prepareParameters(result, body) {
    this.ssoTokenParameters = new SsoTokenParameters('', '', result.access_token, result.token_type, result.expires_in, body.get('scope'));
  }
  ssoExchangeToken(access_token, token_type) {
    return new Promise((resolve, reject) => {
      if (this.wellKnownConfiguration) {
        this.ssoExchangeToken_Subscribe(access_token, token_type, resolve, reject, this.wellKnownConfiguration);
      } else {
        this.getWellKnownConfiguration().subscribe(wellKnown => {
          this.wellKnownConfiguration = wellKnown;
          this.setExchangeToken(this.wellKnownConfiguration);
          this.ssoExchangeToken_Subscribe(access_token, token_type, resolve, reject, this.wellKnownConfiguration);
        });
      }
    });
  }
  ssoExchangeToken_Subscribe(access_token, token_type, resolve, reject, wellKnown) {
    this.getSsoExchangeToken(access_token, token_type, wellKnown).subscribe(res => {
      localStorage.setItem(authSessionDataEnum.accessToken, res.access_token);
      localStorage.setItem(authSessionDataEnum.accessTokenType, res.token_type);
      const expiration = new Date();
      expiration.setSeconds(expiration.getSeconds() + Number(res.expires_in));
      localStorage.setItem(authSessionDataEnum.tokenExpiresAt, expiration.toString());
      localStorage.setItem(authSessionDataEnum.ssoUser, JSON.stringify(SsoHelper.decodeJwtToken(res.access_token)));
      this.userService.currentUserSubject.next(SsoHelper.decodeJwtToken(res.access_token));
      resolve(res);
    }, rej => {
      reject(rej);
    });
  }
  getSsoExchangeToken(access_token, token_type, wellKnown) {
    return this.http.get(wellKnown.exchangeToken_endpoint, {
      headers: new HttpHeaders().set('Authorization', token_type + ' ' + access_token).set('Cache-Control', 'no-cache')
    });
  }
  static {
    this.ɵfac = function LoginService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoginService)(i0.ɵɵinject(i1$1.HttpClient), i0.ɵɵinject(SsoConfiguration), i0.ɵɵinject(UserService), i0.ɵɵinject(L10nService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LoginService,
      factory: LoginService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoginService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1$1.HttpClient
    }, {
      type: SsoConfiguration
    }, {
      type: UserService
    }, {
      type: L10nService
    }];
  }, null);
})();
class CommonHelpers {
  constructor() {}
  static calculateDistanceBetweenCoordinates(latitude1, longitude1, latitude2, longitude2, fractionDigits = 0) {
    const radlat1 = Math.PI * latitude1 / 180;
    const radlat2 = Math.PI * latitude2 / 180;
    const theta = longitude1 - longitude2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    return Number(dist.toFixed(fractionDigits));
  }
}
class CompareService {
  constructor() {}
  sortArray(a, b) {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }
  compareString(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc) {
    return a && b ? (a < b ? -1 : 1) * (isAsc ? 1 : -1) : 0;
  }
  compareMomentTimeWithoutDates(moment1, moment2, isAsc) {
    const a = moment1.format('HH:mm');
    const b = moment2.format('HH:mm');
    return a && b ? (a < b ? -1 : 1) * (isAsc ? 1 : -1) : 0;
  }
  compareMomentDates(a, b, isAsc) {
    return a && b ? (a < b ? -1 : 1) * (isAsc ? 1 : -1) : 0;
  }
  compareNumber(a, b, isAsc) {
    return a != null && b != null ? (a < b ? -1 : 1) * (isAsc ? 1 : -1) : 0;
  }
  compareBoolean(a, b, isAsc) {
    return a === b ? 0 : a && isAsc ? 1 : -1;
  }
  static {
    this.ɵfac = function CompareService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CompareService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CompareService,
      factory: CompareService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CompareService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class GeoPositionService {
  constructor() {}
  savePosition(position) {
    const geoPosition = GeoPosition.of(position);
    localStorage.setItem('userGeoPosition', JSON.stringify(geoPosition));
  }
  isPositionSaved() {
    return !!localStorage.getItem('userGeoPosition');
  }
  getUsersPosition() {
    if (this.isPositionSaved()) {
      return JSON.parse(`${localStorage.getItem('userGeoPosition')}`);
    }
    return null;
  }
  calculateDistance(point1, point2) {
    const radius = 6371; // Radius of the Earth in kilometers
    // Convert degrees to radians
    const lat1Rad = this.toRadians(point1.latitude);
    const lon1Rad = this.toRadians(point1.longitude);
    const lat2Rad = this.toRadians(point2.latitude);
    const lon2Rad = this.toRadians(point2.longitude);
    // Haversine formula
    const dlat = lat2Rad - lat1Rad;
    const dlon = lon2Rad - lon1Rad;
    const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dlon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = radius * c;
    return distance;
  }
  toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }
  static {
    this.ɵfac = function GeoPositionService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GeoPositionService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GeoPositionService,
      factory: GeoPositionService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GeoPositionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class GoogleAnalyticsService {
  constructor(router) {
    this.router = router;
    this.router.events.subscribe(event => {
      if (typeof ga === 'function') {
        if (event instanceof NavigationEnd) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }
      }
    });
  }
  appendGoogleAnalyticsTrackingCode(googleAnalyticsCode) {
    const script = document.createElement('script');
    script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', '` + googleAnalyticsCode + `', 'auto');`;
    if (document.head) {
      document.head.appendChild(script);
    }
  }
  static {
    this.ɵfac = function GoogleAnalyticsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GoogleAnalyticsService)(i0.ɵɵinject(i2.Router));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GoogleAnalyticsService,
      factory: GoogleAnalyticsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleAnalyticsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i2.Router
    }];
  }, null);
})();
class FormatDateService {
  constructor(translate) {
    this.translate = translate;
    this.monthsForwardBackward = 3;
  }
  formatDate(date) {
    return [date.getFullYear(), this.padTo2Digits(date.getMonth() + 1), this.padTo2Digits(date.getDate())].join('-');
  }
  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  isMinDate(selectDate) {
    const minDate = new Date(new Date().getFullYear(), new Date().getMonth() - this.monthsForwardBackward, 1);
    return minDate.getTime() === this.getDateWithoutTime(selectDate).getTime();
  }
  isMaxDate(selectDate) {
    const maxDate = new Date(new Date().getFullYear(), new Date().getMonth() + this.monthsForwardBackward + 1, 0);
    return maxDate.getTime() === this.getDateWithoutTime(selectDate).getTime();
  }
  getDateWithoutTime(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }
  getTranslatedDay(date) {
    const time = date ? date : new Date();
    const options = {
      weekday: 'long',
      timeZone: 'UTC'
    };
    const day = time.toLocaleDateString(this.translate.currentLang, options);
    return day.charAt(0).toUpperCase() + day.slice(1);
  }
  static {
    this.ɵfac = function FormatDateService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FormatDateService)(i0.ɵɵinject(i1.TranslateService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FormatDateService,
      factory: FormatDateService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormatDateService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.TranslateService
    }];
  }, null);
})();
class SignalRService {
  constructor() {
    this.connections = [];
    this.logLevel = LogLevel.Information;
    this.defaultConfiguration = {
      isLogged: true,
      timeout: 10000,
      skipNegotiation: false,
      transport: HttpTransportType.LongPolling
    };
  }
  initOrGet(connectionName, url, conf) {
    const configuration = conf ? {
      ...this.defaultConfiguration,
      ...conf
    } : {
      ...this.defaultConfiguration
    };
    const foundConnection = this.connections.find(connection => connection.connectionName === connectionName);
    if (!foundConnection) {
      return this.init(connectionName, url, configuration);
    }
    return foundConnection;
  }
  startConnection(connectionName) {
    const foundConnection = this.connections.find(connection => connection.connectionName === connectionName);
    if (foundConnection) {
      return foundConnection.startConnection();
    }
    return null;
  }
  stopConnection(connectionName) {
    const foundConnectionIndex = this.connections.findIndex(connection => connection.connectionName === connectionName);
    if (foundConnectionIndex > -1) {
      const foundConnection = {
        ...this.connections[foundConnectionIndex]
      };
      this.connections.splice(foundConnectionIndex, 1);
      return foundConnection.stopConnection();
    }
    return null;
  }
  onNewMessage(connectionName, eventName, callback) {
    const foundConnection = this.connections.find(connection => connection.connectionName === connectionName);
    if (foundConnection) {
      foundConnection.onNewMessage(eventName, callback);
    }
  }
  sendOperationMessage(connectionName, eventName, op) {
    const foundConnection = this.connections.find(connection => connection.connectionName === connectionName);
    if (foundConnection) {
      foundConnection.sendOperationMessage(eventName, op);
    }
  }
  sendGeneralMessage(connectionName, eventName, message) {
    const foundConnection = this.connections.find(connection => connection.connectionName === connectionName);
    if (foundConnection) {
      foundConnection.sendGeneralMessage(eventName, message);
    }
  }
  init(connectionName, url, conf) {
    const hubConnection = new HubConnectionBuilder().configureLogging(this.logLevel).withUrl(url, {
      transport: conf.transport,
      skipNegotiation: conf.skipNegotiation,
      accessTokenFactory: () => this.token,
      timeout: conf.timeout
    }).withAutomaticReconnect().build();
    if (conf.isLogged) {
      hubConnection.onclose(error => {
        console.log('SignalR connection closed:', error);
      });
      hubConnection.onreconnecting(error => {
        console.log('SignalR connection reconnecting:', error);
      });
      hubConnection.onreconnected(connectionId => {
        console.log('SignalR connection reconnected. Connection ID:', connectionId);
      });
    }
    const connecionManager = new ConnectionManager(connectionName, hubConnection, conf, false);
    this.connections.push(connecionManager);
    return connecionManager;
  }
  get token() {
    const token = localStorage.getItem('authSessionData_accessToken');
    return `${token}`;
  }
  static {
    this.ɵfac = function SignalRService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SignalRService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SignalRService,
      factory: SignalRService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SignalRService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class OfflineService {
  static {
    this.isOnline = new BehaviorSubject(false);
  }
  static {
    this.onlineEvent = new Observable();
  }
  static {
    this.offlineEvent = new Observable();
  }
  static initialize() {
    if (navigator && navigator.onLine) {
      this.isOnline.next(true);
    }
    this.addStaticOnlineListener();
    this.addStaticOfflineListener();
  }
  static addStaticOnlineListener() {
    this.onlineEvent = fromEvent(window, 'online');
    this.onlineEvent.subscribe(e => {
      this.isOnline.next(true);
    });
  }
  static addStaticOfflineListener() {
    this.offlineEvent = fromEvent(window, 'offline');
    this.offlineEvent.subscribe(e => {
      this.isOnline.next(false);
    });
  }
  static {
    this.ɵfac = function OfflineService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OfflineService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OfflineService,
      factory: OfflineService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OfflineService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class RequestStorageService {
  constructor() {
    this.requestsLocalArray = Array();
  }
  handleConnectionError(request) {
    this.retrieveRequestsFromLocalStorage();
    const newRequest = {
      url: request.url,
      headers: request.headers,
      method: request.method,
      mapper: request.mapper,
      callback: request.callback,
      body: request.body
    };
    this.requestsLocalArray.push(newRequest);
    this.saveToLocalStorage();
  }
  clearRequestsToRepeat() {
    this.requestsLocalArray = new Array();
    localStorage.setItem('requests', '');
  }
  getRequestToRepeat() {
    return this.requestsLocalArray != null ? this.requestsLocalArray : new Array();
  }
  saveToLocalStorage() {
    localStorage.setItem('requests', JSON.stringify(this.requestsLocalArray));
  }
  retrieveRequestsFromLocalStorage() {
    const requestString = localStorage.getItem('requests');
    this.requestsLocalArray = requestString ? JSON.parse(requestString) : new Array();
  }
  static {
    this.ɵfac = function RequestStorageService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || RequestStorageService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RequestStorageService,
      factory: RequestStorageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RequestStorageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class HttpService {
  constructor(httpClient, requestStorageService, offlineService) {
    this.httpClient = httpClient;
    this.requestStorageService = requestStorageService;
    this.offlineService = offlineService;
    this.res = new Request(HttpMethod.GET);
    this.resBody = new RequestWithBody(HttpMethod.GET);
    this.isOnline = navigator.onLine;
    this.subscribeOnlineStatus();
  }
  /**
   * @deprecated This method should not be used anymore. Please use getObs<T>
   *  In the near future this method will be replaced with the code in getObs<T> and will be returning Observable<T> object
   * @param request
   * @param errorCallback
   */
  get(request, errorCallback) {
    this.httpClient.get(request.url, this.options).subscribe(request.callback, err => errorCallback ? errorCallback(err) : this.handleError(err), undefined);
  }
  getObs(url, errorCallback) {
    return this.httpClient.get(url, this.options).pipe(catchError(err => {
      if (errorCallback) {
        errorCallback(err);
      }
      return of(this.handleError(err));
    }));
  }
  /**
   * @deprecated This method should not be used anymore. Please use postObs<T>
   *  In the near future this method will be replaced with the code in postObs<T> and will be returning Observable<T> object
   * @param request
   * @param errorCallback
   * @param options
   */
  post(request, errorCallback, options) {
    // TODO: errorCallback will be needed in the future - then handle it
    if (options) {
      options.headers = this.addAuthorizationToOptions(options);
    }
    this.httpClient.post(request.url, request.body || {}, options || this.options).subscribe(request.callback, err => this.handlePostError(err, request, errorCallback));
  }
  postObs(url, body, errorCallback, options) {
    if (options) {
      options.headers = this.addAuthorizationToOptions(options);
    }
    return this.httpClient.post(url, body || {}, options || this.options).pipe(catchError(err => {
      if (errorCallback) {
        errorCallback(err);
      }
      return of(this.handleError(err));
    }));
  }
  /**
   * @deprecated This method should not be used anymore. Please use putObs<T>
   *  In the near future this method will be replaced with the code in putObs<T> and will be returning Observable<T> object
   * @param request
   * @param errorCallback
   */
  put(request, errorCallback) {
    this.httpClient.put(request.url, request.body || {}, this.options).subscribe(request.callback, err => errorCallback ? errorCallback(err) : this.handleError(err), undefined);
  }
  putObs(url, body, errorCallback) {
    return this.httpClient.put(url, body || {}, this.options).pipe(catchError(err => {
      if (errorCallback) {
        errorCallback(err);
      }
      return of(this.handleError(err));
    }));
  }
  /**
   * @deprecated This method should not be used anymore. Please use deleteObs<T>
   *  In the near future this method will be replaced with the code in deleteObs<T> and will be returning Observable<T> object
   * @param request
   * @param errorCallback
   */
  delete(request, errorCallback) {
    this.httpClient.delete(request.url, this.options).subscribe(request.callback, err => errorCallback ? errorCallback(err) : this.handleError(err), undefined);
  }
  deleteObs(url, errorCallback) {
    return this.httpClient.delete(url, this.options).pipe(catchError(err => {
      if (errorCallback) {
        errorCallback(err);
      }
      return of(this.handleError(err));
    }));
  }
  /**
   * @deprecated This method should not be used anymore. Please use patchObs<T>
   *  In the near future this method will be replaced with the code in patchObs<T> and will be returning Observable<T> object
   * @param request
   * @param errorCallback
   * @param options
   */
  patch(request, errorCallback, options) {
    if (options) {
      options.headers = this.addAuthorizationToOptions(options);
    }
    this.httpClient.patch(request.url, request.body || {}, options || this.options).subscribe(request.callback, err => this.handlePostError(err, request, errorCallback));
  }
  patchObs(url, body, errorCallback, options) {
    if (options) {
      options.headers = this.addAuthorizationToOptions(options);
    }
    return this.httpClient.patch(url, body || {}, options || this.options).pipe(catchError(err => {
      if (errorCallback) {
        errorCallback(err);
      }
      return of(this.handleError(err));
    }));
  }
  subscribeOnlineStatus() {
    OfflineService.initialize();
    OfflineService.isOnline.subscribe(x => this.handleConnectionStateChange(x));
  }
  get options() {
    let token = localStorage.getItem(authSessionDataEnum.accessToken);
    if (token == null) {
      return {};
    }
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token
      })
    };
  }
  addAuthorizationToOptions(options) {
    if (options.headers && this.options.headers) {
      return options.headers.append('Authorization', this.options.headers.get('Authorization'));
    }
    return undefined;
  }
  handleError(error) {
    console.error('Error occurred while calling external resource\n\tERROR:\t', error.statusText);
  }
  handlePostError(error, request, errorCallback) {
    if (!this.isOnline) {
      this.requestStorageService.handleConnectionError(request);
    } else {
      if (errorCallback && typeof errorCallback == 'function') {
        errorCallback(error);
      }
    }
  }
  handleConnectionStateChange(state) {
    this.isOnline = state;
    if (state) {
      this.repeatQueries();
    }
  }
  repeatQueries() {
    const requests = this.requestStorageService.getRequestToRepeat();
    if (requests.length === 0) {} else {
      const uniqueRequests = requests.filter((req, index, self) => index === self.findIndex(x => JSON.stringify(x) === JSON.stringify(req)));
      uniqueRequests.forEach(element => {
        this.post(element);
      });
      this.requestStorageService.clearRequestsToRepeat();
    }
  }
  static {
    this.ɵfac = function HttpService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HttpService)(i0.ɵɵinject(i1$1.HttpClient), i0.ɵɵinject(RequestStorageService), i0.ɵɵinject(OfflineService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HttpService,
      factory: HttpService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HttpService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1$1.HttpClient
    }, {
      type: RequestStorageService
    }, {
      type: OfflineService
    }];
  }, null);
})();
class PushNotificationService {
  constructor() {
    this.permission = this.isSupported() ? 'default' : 'denied';
  }
  isSupported() {
    return 'Notification' in window;
  }
  requestPermission() {
    if ('Notification' in window) {
      Notification.requestPermission(status => {
        this.permission = status;
      });
    }
  }
  create(title, options) {
    return new Observable(obs => {
      if (!('Notification' in window)) {
        console.log('Notifications are not available in this environment');
        obs.complete();
      }
      if (this.permission !== 'granted') {
        console.log("The user hasn't granted you permission to send push notifications");
        obs.complete();
      }
      let notify = new Notification(title, options);
      notify.onshow = e => {
        return obs.next({
          notification: notify,
          event: e
        });
      };
      notify.onclick = e => {
        window.parent.parent.focus(); //Focus to current selected tab.
      };
      notify.onerror = e => {
        return obs.error({
          notification: notify,
          event: e
        });
      };
      notify.onclose = () => {
        return obs.complete();
      };
    });
  }
  generateNotification(source) {
    source.forEach(item => {
      let options = {
        body: item.body,
        icon: item.icon
      };
      this.create(item.title, options).subscribe();
    });
  }
  static {
    this.ɵfac = function PushNotificationService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PushNotificationService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PushNotificationService,
      factory: PushNotificationService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PushNotificationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class VersionCheckService {
  constructor(swUpdates) {
    this.swUpdates = swUpdates;
  }
  initAutoReload() {
    if (this.swUpdates.isEnabled) {
      this.swUpdates.versionUpdates.pipe(filter(evt => evt.type === 'VERSION_READY')).subscribe(evt => {
        if (evt) {
          document.location.reload();
        }
      });
    }
  }
  static {
    this.ɵfac = function VersionCheckService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || VersionCheckService)(i0.ɵɵinject(i1$2.SwUpdate));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: VersionCheckService,
      factory: VersionCheckService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VersionCheckService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1$2.SwUpdate
    }];
  }, null);
})();
class SsoCallbackComponent {
  constructor(ssoLoginService, router) {
    this.ssoLoginService = ssoLoginService;
    this.router = router;
  }
  ngOnInit() {
    this.ssoLoginService.authenticateCallback(this.router.url);
  }
  static {
    this.ɵfac = function SsoCallbackComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SsoCallbackComponent)(i0.ɵɵdirectiveInject(LoginService), i0.ɵɵdirectiveInject(i2.Router));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SsoCallbackComponent,
      selectors: [["lib-sso-callback"]],
      decls: 0,
      vars: 0,
      template: function SsoCallbackComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SsoCallbackComponent, [{
    type: Component,
    args: [{
      selector: 'lib-sso-callback',
      template: ''
    }]
  }], function () {
    return [{
      type: LoginService
    }, {
      type: i2.Router
    }];
  }, null);
})();
class SsoExternalComponent {
  constructor(ssoLoginService, router) {
    this.ssoLoginService = ssoLoginService;
    this.router = router;
  }
  ngOnInit() {
    const searchParams = new URLSearchParams(this.router.url.split('#')[1]);
    if (searchParams && searchParams.get('redirectToRoute')) {
      this.ssoLoginService.authenticateExternalWithRouteRedirection(this.router.url, searchParams.get('redirectToRoute'));
    } else {
      this.ssoLoginService.authenticateExternal(this.router.url);
    }
  }
  static {
    this.ɵfac = function SsoExternalComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SsoExternalComponent)(i0.ɵɵdirectiveInject(LoginService), i0.ɵɵdirectiveInject(i2.Router));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SsoExternalComponent,
      selectors: [["lib-sso-external"]],
      decls: 0,
      vars: 0,
      template: function SsoExternalComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SsoExternalComponent, [{
    type: Component,
    args: [{
      selector: 'lib-sso-external',
      template: ''
    }]
  }], function () {
    return [{
      type: LoginService
    }, {
      type: i2.Router
    }];
  }, null);
})();
class SsoLoginComponent {
  constructor(ssoLoginService, activatedRoute) {
    this.ssoLoginService = ssoLoginService;
    this.activatedRoute = activatedRoute;
    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
    });
  }
  ngOnInit() {
    this.ssoLoginService.login(new UrlParameters(window.location.host, this.params));
  }
  static {
    this.ɵfac = function SsoLoginComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SsoLoginComponent)(i0.ɵɵdirectiveInject(LoginService), i0.ɵɵdirectiveInject(i2.ActivatedRoute));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SsoLoginComponent,
      selectors: [["lib-login"]],
      decls: 0,
      vars: 0,
      template: function SsoLoginComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SsoLoginComponent, [{
    type: Component,
    args: [{
      selector: 'lib-login',
      template: ''
    }]
  }], function () {
    return [{
      type: LoginService
    }, {
      type: i2.ActivatedRoute
    }];
  }, null);
})();
Date.prototype.getDateWithoutTimezoneOffset = function () {
  const currentDate = this;
  const timezoneOffset = currentDate.getTimezoneOffset() * 60000;
  const dateWihoutTimezoneOffset = new Date(currentDate.getTime() - timezoneOffset);
  return dateWihoutTimezoneOffset;
};
Date.prototype.formatDate = function () {
  const date = this;
  const momentDate = moment(date);
  return momentDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
};
Date.prototype.formatFromDate = function () {
  const date = this;
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  const momentDate = moment(date);
  return momentDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
};
Date.prototype.formatToDate = function () {
  const date = this;
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  const momentDate = moment(date);
  return momentDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
};
Date.prototype.formatWithMoment = function (format) {
  const date = this;
  const momentDate = moment(date);
  return momentDate.format(format);
};
class ExtensionModule {
  constructor() {}
  static {
    this.ɵfac = function ExtensionModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ExtensionModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ExtensionModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExtensionModule, [{
    type: NgModule,
    args: [{
      declarations: [SsoCallbackComponent, SsoLoginComponent, SsoExternalComponent],
      imports: [],
      exports: [SsoCallbackComponent, SsoLoginComponent, SsoExternalComponent]
    }]
  }], function () {
    return [];
  }, null);
})();
class NetworkService {
  constructor() {
    this.online$ = new Observable();
    this.online = false;
    this.online$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(map(() => navigator.onLine));
    this.online$.subscribe(value => {
      this.online = value;
    });
  }
  static {
    this.ɵfac = function NetworkService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NetworkService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NetworkService,
      factory: NetworkService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NetworkService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
const DRIFTMEDDELANDE_ID = 174;
const EXCLUDED_LEDA_PERSONAL_ID = 29;
class ProcessTagTreeService {
  constructor() {
    this.iconMapping = [{
      id: 25,
      path: 'ico_GenomforaFard.svg'
    }, {
      id: 30,
      path: 'ico_StartaTjanstepass.svg'
    }, {
      id: 39,
      path: 'ico_AvslutaTjanstepass.svg'
    }, {
      id: 153,
      path: 'ico_VaxlaRangera.svg'
    }];
  }
  minimizeTagsList(tags) {
    // ID: 23 = "Genomföra resa"
    let rootIndex = tags.findIndex(element => element.ID === 23);
    if (rootIndex === -1) {
      rootIndex = 0;
    }
    return tags.slice(rootIndex + 1).filter(tag => tag.ID !== DRIFTMEDDELANDE_ID && tag.ID !== EXCLUDED_LEDA_PERSONAL_ID).map(element => new Category(element));
  }
  buildProcessTagTree(categories) {
    categories.forEach(element => {
      const parent = categories.find(category => category.processTag.ID === element.processTag.ParentProcessTagID);
      if (parent) {
        element.parent = parent;
        parent.children.push(element);
      }
      this.initIcon(element);
    });
    return categories[0];
  }
  fillCategoriesWithDocuments(documents, categories) {
    documents.forEach(doc => {
      const category = categories.find(cat => cat.processTag.ID === doc.ProcessTagID);
      if (category) {
        category.documents.push(doc);
      }
    });
  }
  initMessagesCategory(tags) {
    const messagesCategory = new Category(tags.find(element => element.ID === DRIFTMEDDELANDE_ID));
    messagesCategory.isCategoryNameVisible = false;
    messagesCategory.icoPath = 'ico_Driftmeddelande.svg';
    messagesCategory.specialCategory = SpecialCategoryType.MESSAGES;
    return messagesCategory;
  }
  getMessagesDocs(data) {
    return data.filter(doc => doc.ProcessTagID === DRIFTMEDDELANDE_ID);
  }
  getUnreadDocs(data) {
    return data.filter(doc => doc.ReadReceipt && !doc.UserChecked);
  }
  getFavouritesDocs(data, favouritesDocumentsIds) {
    return data.filter(doc => favouritesDocumentsIds.findIndex(id => doc.ID === +id) !== -1);
  }
  initIcon(category) {
    const mappedIcon = this.iconMapping.find(element => element.id === category.processTag.ID);
    if (mappedIcon) {
      category.icoPath = mappedIcon.path;
    }
  }
  static {
    this.ɵfac = function ProcessTagTreeService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ProcessTagTreeService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ProcessTagTreeService,
      factory: ProcessTagTreeService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProcessTagTreeService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();

/*
 * Public API Surface of vr-common-lib
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AuthGuardService, Category, CommonHelpers, CompareService, ConnectionManager, Document, ExtensionModule, FormatDateService, GeoPosition, GeoPositionService, GoogleAnalyticsService, Header, HeaderDropdownItem, HttpMethod, HttpOptions, HttpService, L10nService, LoginService, MenuItem, Navigation, NetworkService, OPERATION, OfflineService, ProcessTag, ProcessTagTreeService, PushNotification, PushNotificationService, Request, RequestWithBody, SettingsService, SignalRService, SpecialCategoryType, SsoCallbackComponent, SsoConfiguration, SsoExternalComponent, SsoHelper, SsoLoginComponent, SsoUser, UrlParameters, UserService, VersionCheckService, YYYY_MM_DD_FORMAT };
