<div class="cm-comments" cmAutoScroll>
  <h3>Comments</h3>
  <div class="cm-comments__interaction-wrapper" [formGroup]="form">
    <div class="cm-comments__inputs">
      <div *ngIf="showButtons$ | async" class="cm-comments__checkbox">
        <mat-checkbox (click)="sendToPartnerChange($event)"
          >Send notification to partner</mat-checkbox
        >
      </div>
      <textarea
        cmFocusTextarea
        class="cm-comments__inputs__textarea"
        (focus)="handlerHandler()"
        (input)="onTextInput()"
        rows="1"
        formControlName="comment"
        maxlength="4000"
        placeholder="Add comment..."></textarea>
    </div>

    <div class="cm-comments__buttons" *ngIf="showButtons$ | async">
      <button mat-flat-button (click)="submitComment()">Submit</button>
      <button mat-button (click)="cancel()">Cancel</button>
    </div>
  </div>
  <br />
  <div
    class="cm-comments__comments"
    *ngIf="data.comments && data.comments.length > 0; else noData">
    <div *ngFor="let comment of data.comments">
      <div class="cm-comments__comments__header">
        <div class="cm-comments__comments__header__content">
          <span *ngIf="comment.author.name">{{ comment.author.name }}</span>
          <span>{{ comment.createdAt | dateFormatPipe }}</span>
          <ng-container *ngIf="createdCommentId$ | async as id">
            <div *ngIf="id.id === comment.id" class="cm-dot-into"></div>
          </ng-container>
        </div>

        <div
          *ngIf="comment.visibility === 'external'"
          class="cm-comments__comments__header__external-hint">
          <div class="external-hint">
            <span>Sent to external partner</span>
          </div>
        </div>
      </div>

      <div *ngFor="let content of comment.content">
        <ng-container *ngIf="content.type === descriptionType[0]">
          <ng-container *ngFor="let text of content.content">
            <span *ngIf="text?.type === descriptionType[1]">
              {{ text?.text ?? '-' }}
            </span>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #noData>
    <div class="cm-comments__no-comments-wrapper">
      <div class="content">
        <span>{{ 'There are no comments on this case yet.' }}</span>
      </div>
    </div>
  </ng-template>
</div>
