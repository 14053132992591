import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatTreeModule } from '@angular/material/tree';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { VrStorybookLibModule } from '@vr/vr-storybook-lib';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { AuthInterceptor } from './shared/services/auth/authInterceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderOverlayComponent } from './shared/components/loader-overlay/loader-overlay.component';
import { ErrorInterceptor } from '@shared/interceptors/error.interceptor';
import { NotFoundComponent } from './not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { LoginComponent } from './login/login.component';
import { PageHeaderComponent } from './shared/components/page-header/page-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { L10nService } from '@vr/vr-common-lib';
import { ErrorDisplayComponent } from '@shared/components/error-display/error-display.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { UserMenuComponent } from '@shared/components/user-menu/user-menu.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [
    LoaderOverlayComponent,
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    ErrorDisplayComponent,
    LayoutComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    PageHeaderComponent,
    MatListModule,
    MatTreeModule,
    MatDividerModule,
    UserMenuComponent,
    MatDialogModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    VrStorybookLibModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
  ],
  providers: [
    L10nService,
    DatePipe,
    {
      provide: 'translation',
      useValue: require('../assets/translations/sv.json'),
    },
    {
      provide: 'defaultTranslation',
      useValue: require('../assets/translations/en.json'),
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'ISO' },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
