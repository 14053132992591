<cm-page-header
  [title]="'Create Case'"
  [breadcrumbList]="[
    { label: 'Cases', url: '/', hidden: false },
    { label: 'New case', url: '/create-case', hidden: false }
  ]">
</cm-page-header>
<div class="cm-edit-container">
  <div>
    <div class="cm-card">
      <div class="cm-card__header">
        <storybook-label
          content="1"
          [type]="labelType.Main3semibold"
          class="cm-card__circle"
          color="var(--white, #fff)"></storybook-label>
        <storybook-label
          content="Basic information"
          [type]="labelType.H3semibold"></storybook-label>
      </div>
      <div class="cm-card__line"></div>

      <form [formGroup]="basic" class="cm-form">
        <storybook-input
          label="Case summary *"
          [control]="basic.controls.summary"
          [focusTrigger]="true"></storybook-input>
        <storybook-input
          label="Reference Id *"
          [control]="basic.controls.referenceId"></storybook-input>
        <storybook-dropdown
          [canBeEmpty]="false"
          [items]="['Customer Opinion']"
          label="Type *"
          [control]="basic.controls.caseType" />
        <storybook-dropdown
          [canBeEmpty]="false"
          [items]="['SL', 'TiB', 'ÖT', 'ST']"
          label="Source *"
          [control]="basic.controls.source" />
        <storybook-dropdown
          [canBeEmpty]="false"
          [items]="['Ekerö', 'Råsta', 'Lunda', 'TIB']"
          label="District *"
          [control]="basic.controls.district" />
      </form>
    </div>

    <div class="cm-card">
      <div class="cm-card__header">
        <storybook-label
          content="2"
          [type]="labelType.Main3semibold"
          class="cm-card__circle"
          color="var(--white, #fff)"></storybook-label>
        <storybook-label
          content="Case details"
          [type]="labelType.H3semibold"></storybook-label>
      </div>
      <div class="cm-card__line"></div>
      <form [formGroup]="details" class="cm-form">
        <storybook-label
          content="Enter trip data"
          [type]="labelType.H5semibold"></storybook-label>
        <storybook-input
          label="From"
          [control]="details.controls.fromStation"></storybook-input>
        <storybook-input
          label="To"
          [control]="details.controls.toStation"></storybook-input>
        <storybook-input
          label="Line number"
          [control]="details.controls.line"></storybook-input>
        <storybook-input
          label="Vehicle number"
          [control]="details.controls.vehicleNumber"></storybook-input>
        <storybook-label
          content="Choose max. 2 categories of this case"
          [type]="labelType.H5semibold"></storybook-label>
        <storybook-dropdown
          [items]="cat1"
          label="Category 1"
          [control]="details.controls.category1"></storybook-dropdown>
        <storybook-dropdown
          [items]="cat2"
          label="Category 2"
          [control]="details.controls.category2"></storybook-dropdown>
        <!-- <storybook-input
          label="Category 3"
          [control]="details.controls.category3"></storybook-input> -->
        <!-- <mat-form-field>
          <mat-select
            name="sentTo"
            placeholder="Sent To"
            [formControl]="details.controls.sentTo"
            multiple>
            <mat-option
              *ngFor="let recipient of ['THR', 'Ecase', 'Other']"
              [value]="recipient"
              >{{ recipient }}</mat-option
            >
          </mat-select>
        </mat-form-field> -->
        <storybook-input
          label="Staff Member Id"
          [control]="details.controls.staffMemberId"></storybook-input>
        <storybook-input
          label="Role"
          [control]="details.controls.role"></storybook-input>
      </form>
    </div>

    <div class="cm-card">
      <div class="cm-card__header">
        <storybook-label
          content="3"
          [type]="labelType.Main3semibold"
          class="cm-card__circle"
          color="var(--white, #fff)"></storybook-label>
        <storybook-label
          content="Description"
          [type]="labelType.H3semibold"></storybook-label>
      </div>
      <div class="cm-card__line"></div>

      <form [formGroup]="description" class="cm-form">
        <mat-form-field
          [formControl]="description.controls.eventDate"
          class="example-full-width">
          <mat-label>Event date</mat-label>
          <input
            matInput
            [matDatepicker]="event"
            (dateChange)="changeEventDate($event)" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="event"></mat-datepicker-toggle>
          <mat-datepicker #event></mat-datepicker>
        </mat-form-field>

        <mat-form-field
          [formControl]="description.controls.reportedAt"
          class="example-full-width">
          <mat-label>Reported at</mat-label>
          <input
            matInput
            [matDatepicker]="reported"
            (dateChange)="changeReportedAt($event)" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="reported"></mat-datepicker-toggle>
          <mat-datepicker #reported></mat-datepicker>
        </mat-form-field>

        <storybook-input
          label="Add case description"
          [type]="inputType.DESCRIPTION"
          [control]="description.controls.description"></storybook-input>
        <storybook-switch
          label="Case has missing information"
          (onChange)="missingInformationChange($event)" />
        <storybook-switch
          label="Wrongdoing can’t be determined"
          (onChange)="nothingWrongChange($event)" />
      </form>
    </div>

    <div class="cm-card">
      <div class="cm-card__header">
        <storybook-label
          content="4"
          [type]="labelType.Main3semibold"
          class="cm-card__circle"
          color="var(--white, #fff)" />
        <storybook-label
          content="Customer info"
          [type]="labelType.H3semibold" />
      </div>
      <div class="cm-card__line"></div>
      <form [formGroup]="customerInfo" class="cm-form">
        <storybook-input
          label="Full name"
          [control]="customerInfo.controls.customerName" />
        <storybook-input
          label="Email"
          [control]="customerInfo.controls.customerEmail" />
        <storybook-input
          label="Phone"
          [control]="customerInfo.controls.customerPhone" />
        <storybook-switch
          label="Customer feedback required"
          (onChange)="customerFeedbackActionChange($event)" />
      </form>
    </div>

    <storybook-button-v2
      label="Create case"
      [type]="ButtonTypeV2.SECONDARY"
      [size]="ButtonSizeV2.MEDIUM"
      (onClick)="saveCase()"></storybook-button-v2>
  </div>
</div>
