import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoryDetails } from 'src/app/categories/category.model';

export function editCategoryFormGroup(categoryDetails?: CategoryDetails): FormGroup {
  return new FormGroup({
    id: new FormControl<number | null>({ value: categoryDetails?.id ?? null, disabled: true }),
    parentId: new FormControl<number | null>(categoryDetails?.parentId ?? null),
    caseTypeInstanceId: new FormControl<number | null>({
      value: categoryDetails?.caseTypeInstanceId ?? null,
      disabled: true,
    }),
    name: new FormControl<string | null>(categoryDetails?.name ?? null),
    code: new FormControl<string | null>(categoryDetails?.code ?? null),
    description: new FormControl<string | null>(categoryDetails?.description ?? null),
    caseTypeInstanceSlaId: new FormControl<number | null>(categoryDetails?.caseTypeInstanceSlaId ?? null),
    validFrom: new FormControl(categoryDetails?.validFrom),
    validTill: new FormControl(categoryDetails?.validTill ?? null),
    deployed: new FormControl<boolean>(categoryDetails?.deployed ?? false),
    enabled: new FormControl<boolean>(categoryDetails?.enabled ?? false),
    workgroupId: new FormControl<number | null>(categoryDetails?.workgroupId ?? null),
    escalationPersonVrId: new FormControl<string | null>(categoryDetails?.escalationPersonVrId ?? null),
  });
}

export function createCategoryFormGroup(caseTypeInstanceId: number | null): FormGroup {
  return new FormGroup({
    parentId: new FormControl<number | null>(null),
    caseTypeInstanceId: new FormControl<number | null>(caseTypeInstanceId ?? null, [Validators.required]),
    name: new FormControl<string>('', [Validators.required]),
    code: new FormControl<string>('', [Validators.required]),
    description: new FormControl<string>(''),
    caseTypeInstanceSlaId: new FormControl<number | null>(null),
    validFrom: new FormControl(null, [Validators.required]),
    validTill: new FormControl(null),
    deployed: new FormControl<boolean>(false),
    enabled: new FormControl<boolean>(false),
    workgroupId: new FormControl<number | null>(null),
    escalationPersonVrId: new FormControl<string | null>(''),
  });
}
