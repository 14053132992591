<div class="main-header">
  <mat-icon
    (click)="drawer.toggle()"
    aria-hidden="false"
    aria-label="menu icon"
    fontIcon="menu"></mat-icon>
  <a href="/">
    <img class="logo-img" src="/assets/icons/vr_logo.svg" />
  </a>
  Case Management
  <div class="middle-fill"></div>
  <cm-user-menu></cm-user-menu>
</div>
<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="side">
    <br />
    <br />
    <mat-nav-list>
      <mat-list-item [routerLink]="['/']">
        <span mat-line>Dashboard</span>
      </mat-list-item>
      <mat-list-item [routerLink]="['/cases']">
        <span mat-line>Cases</span>
      </mat-list-item>
    </mat-nav-list>
  </mat-drawer>

  <div class="example-sidenav-content">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
<div class="app__build-version-container">
  <storybook-build-version [version]="spaVersionId"></storybook-build-version>
</div>
