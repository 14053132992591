<ng-container *ngIf="data">
  <cm-page-header
    [title]="data.summary"
    [breadcrumbList]="[
      { label: 'Cases', url: '/', hidden: false },
      { label: data.id, url: '/case/' + data.id, hidden: false },
    ]">
    <button mat-flat-button [routerLink]="['edit']">Edit case</button>
  </cm-page-header>

  <div class="cm-detail-page">
    <div class="cm-detail-page__cards">
      <cm-basic-info [data]="data"></cm-basic-info>
      <cm-description [data]="data"></cm-description>
      <cm-comments [data]="data"></cm-comments>
    </div>
    <div class="cm-detail-page__cards">
      <cm-action-details [data]="data"></cm-action-details>
      <cm-case-details [data]="data"></cm-case-details>
      <cm-customer-info></cm-customer-info>
    </div>
  </div>
</ng-container>
