import { Injectable, ViewContainerRef } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ErrorDisplayComponent } from '../components/error-display/error-display.component';
import { catchError } from 'rxjs/operators';
import { ErrorContainerService } from '../services/error-container-service.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorContainerService: ErrorContainerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleError(error);
        throw error;
      })
    );
  }

  private handleError(error: HttpErrorResponse): void {
    const viewContainerRef = this.getViewContainerRef();
    const componentRef = viewContainerRef.createComponent(
      ErrorDisplayComponent
    );

    componentRef.instance.title = error.name;
    componentRef.instance.subTitle = error.error;

    componentRef.instance.closeErrorClick.subscribe(() => {
      componentRef.destroy();
    });
  }

  private getViewContainerRef(): ViewContainerRef {
    return <ViewContainerRef>this.errorContainerService.viewContainerRef;
  }
}
