<div class="yes-no-dialog">
  <div>
    <button
      aria-label="Close"
      (click)="onCancel.emit($event)"
      class="close-icon pointer">
      <mat-icon fontIcon="close"></mat-icon>
    </button>
    <div [ngSwitch]="data.dialogAppearence"></div>
    <h2 class="title">
      {{ data.title }}
    </h2>
    <p *ngIf="data.message">{{ data.message }}</p>
    <ng-content></ng-content>
    <div class="button-container">
      <ng-container *ngIf="!data.hideCancel">
        <button
          mat-stroked-button
          (click)="onCancel.emit($event)"
          class="button-cancel">
          {{ data.cancelLabel }}
        </button>
      </ng-container>
      <button mat-flat-button (click)="onConfirm.emit($event)">
        {{ data.confirmLabel }}
      </button>
    </div>
  </div>
</div>
