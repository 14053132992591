<mat-dialog-content>
  <form [formGroup]="createForm" class="cm-category-create-form">
    <app-content-wrapper>
      <app-title [number]="1" [title]="'Category details'" />
      <div class="form__row">
        <mat-form-field appearance="outline">
          <mat-label>Parent category</mat-label>
          <mat-select formControlName="parentId">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let category of caseTypeInstanceCategories" [value]="category.id">
              {{category.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Case Type Instance</mat-label>
          <mat-select formControlName="caseTypeInstanceId">
            <mat-option *ngFor="let instance of caseTypeInstances" [value]="instance.id">
              {{instance.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form__row">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Code</mat-label>
          <input matInput formControlName="code" />
        </mat-form-field>
      </div>

      <div class="form-description__row">
        <mat-form-field class="" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
      </div>

<!--      <div class="form__row">-->
<!--        <mat-form-field appearance="outline">-->
<!--          <mat-label>Case Sla Id</mat-label>-->
<!--          <input matInput formControlName="caseTypeInstanceSlaId" />-->
<!--        </mat-form-field>-->
<!--      </div>-->

      <div class="form__row">
        <mat-form-field appearance="outline">
          <mat-label>Valid From</mat-label>
          <input matInput formControlName="validFrom" [matDatepicker]="validFrom" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="validFrom"></mat-datepicker-toggle>
          <mat-datepicker #validFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Valid Till</mat-label>
          <input matInput formControlName="validTill" [matDatepicker]="validTill" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="validTill"></mat-datepicker-toggle>
          <mat-datepicker #validTill></mat-datepicker>
        </mat-form-field>
      </div>

<!--      <div class="form__row">-->
<!--        <mat-form-field appearance="outline">-->
<!--          <mat-label>Workgroup Id</mat-label>-->
<!--          <input matInput formControlName="workgroupId" />-->
<!--        </mat-form-field>-->

<!--        <mat-form-field appearance="outline">-->
<!--          <mat-label>Escalation Person Vr Id</mat-label>-->
<!--          <input matInput formControlName="escalationPersonVrId" />-->
<!--        </mat-form-field>-->
<!--      </div>-->

        <div class="form__row">
          <mat-checkbox formControlName="deployed">Deployed</mat-checkbox>
          <mat-checkbox formControlName="enabled">Enabled</mat-checkbox>
        </div>
    </app-content-wrapper>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="category-action-buttons">
  <button mat-raised-button color="accent" (click)="createItem()">Create</button>
  <button mat-raised-button color="accent" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
