<cm-page-header
  [title]="'Create Case'"
  [breadcrumbList]="[
    { label: 'Cases', url: '/', hidden: false },
    { label: 'New case', url: '/create-case', hidden: false },
  ]">
</cm-page-header>

<div class="cm-edit-page">
  <div class="cm-edit-form">
    <app-content-wrapper>
      <app-title [number]="1" [title]="'Basic information'" />
      <form [formGroup]="basic" class="form">
        <mat-form-field appearance="outline">
          <mat-label>Case summary</mat-label>
          <input matInput formControlName="summary" />
          <!-- <mat-error
            *ngIf="editCaseForm.controls.summary.hasError('required')"
            >Field is required</mat-error> -->
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Reference Id</mat-label>
          <input matInput formControlName="referenceId" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select formControlName="caseType">
            @for (option of ['Customer Opinion']; track option) {
              <mat-option [value]="option">{{ option }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Source</mat-label>
          <mat-select formControlName="source">
            @for (source of ['SL', 'TiB', 'ÖT', 'ST']; track source) {
              <mat-option [value]="source">{{ source }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>District</mat-label>
          <mat-select formControlName="district">
            @for (district of ['Ekerö', 'Råsta', 'Lunda', 'TIB'];
              track district) {
              <mat-option [value]="district">{{ district }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </form>
    </app-content-wrapper>

    <app-content-wrapper>
      <app-title [number]="2" [title]="'Case details'" />
      <form [formGroup]="details" class="form">
        <p>Enter trip data</p>
        <div class="form__row">
          <mat-form-field appearance="outline">
            <mat-label>From</mat-label>
            <input matInput formControlName="fromStation" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>To</mat-label>
            <input matInput formControlName="toStation" />
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Line number</mat-label>
          <input matInput formControlName="line" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Vehicle number</mat-label>
          <input matInput formControlName="vehicleNumber" />
        </mat-form-field>
        <p>Choose max. 2 categories of this case</p>
        <mat-form-field appearance="outline">
          <mat-label>Category 1</mat-label>
          <mat-select formControlName="category1">
            @for (category of cat1; track category) {
              <mat-option [value]="category">{{ category }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Category 2</mat-label>
          <mat-select formControlName="category2">
            @for (category of cat2; track category) {
              <mat-option [value]="category">{{ category }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Staff Member Id</mat-label>
          <input matInput formControlName="staffMemberId" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Role</mat-label>
          <input matInput formControlName="role" />
        </mat-form-field>
      </form>
    </app-content-wrapper>

    <app-content-wrapper>
      <app-title [number]="3" [title]="'Description'" />
      <form [formGroup]="description" class="form">
        <div class="form__row">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Event date</mat-label>
            <input
              formControlName="eventDate"
              matInput
              [matDatepicker]="event"
              (dateChange)="changeEventDate($event)" />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="event"></mat-datepicker-toggle>
            <mat-datepicker #event></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Reported at</mat-label>
            <input
              formControlName="reportedAt"
              matInput
              [matDatepicker]="reported"
              (dateChange)="changeReportedAt($event)" />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="reported"></mat-datepicker-toggle>
            <mat-datepicker #reported></mat-datepicker>
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
        <mat-checkbox formControlName="isMissingInformation"
        >Case has missing information
        </mat-checkbox
        >
        <mat-checkbox formControlName="isNothingWrong"
        >Wrongdoing can’t be determined
        </mat-checkbox
        >
      </form>
    </app-content-wrapper>

    <app-content-wrapper>
      <app-title [number]="4" [title]="'Customer info'" />
      <form [formGroup]="customerInfo" class="form">
        <mat-form-field appearance="outline">
          <mat-label>Full name</mat-label>
          <input matInput formControlName="customerName" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="customerEmail" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Phone</mat-label>
          <input matInput formControlName="customerPhone" />
        </mat-form-field>
        <mat-checkbox formControlName="requiredCustomerFeedbackAction"
        >Customer feedback required
        </mat-checkbox
        >
      </form>
    </app-content-wrapper>

    <button mat-flat-button (click)="saveCase()">Create case</button>
  </div>
</div>
