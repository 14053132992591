import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Hub } from 'aws-amplify';
import { Router } from '@angular/router';
import { ErrorContainerService } from '@shared/services/error-container-service.service';

@Component({
  selector: 'cm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private router: Router,
    private errorContainerService: ErrorContainerService
  ) {
    Hub.listen('auth', (data: { payload: any }) => {
      this.onAuthEvent(data.payload);
    });
  }

  @ViewChild('errorContainer', { read: ViewContainerRef, static: true })
  set content(content: ViewContainerRef) {
    if (content) {
      this.errorContainerService.viewContainerRef = content;
    }
  }

  private onAuthEvent(payload: any) {
    switch (payload.event) {
      case 'signedIn':
        console.log('user have been signedIn successfully.');
        break;
      case 'signedOut':
        console.log('user have been signedOut successfully.');
        break;
      case 'tokenRefresh':
        console.log('auth tokens have been refreshed.');
        break;
      case 'tokenRefresh_failure':
        console.error('failure while refreshing auth tokens.');
        break;
      case 'signInWithRedirect':
        console.log('signInWithRedirect API has successfully been resolved.');
        break;
      case 'signInWithRedirect_failure':
        console.error(
          'failure while trying to resolve signInWithRedirect API.'
        );
        break;
      case 'customOAuthState':
        this.router.navigate([JSON.parse(payload.data).dynamicRedirectUrl]);
        break;
    }
  }
}
