import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormatPipe',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  transform(value: Date) {
    return new Date(value).toLocaleDateString();
  }
}
