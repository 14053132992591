<div class="action-details">
  <h3>Action details</h3>
  <!-- <div class="action-details__actions">
    <cm-case-state-change-button
      [state]="data.status"
      [loading]="isProgress$ | async">
    </cm-case-state-change-button>
  </div> -->

  <div class="action-details__details">
    <span>Status</span>

    <div class="action-details__status">
      <app-status-badge
        [color]="data.status == 'open' ? 'red' : 'gray'"
        [showDot]="true"
        [text]="data.status"></app-status-badge>

      <span class="action-details__resolved-date">{{
        data.resolvedAt | date: 'MMM dd, hh:mm a'
      }}</span>
    </div>

    <span>Related to</span>
    <div class="action-details__related">
      <ng-container
        *ngIf="data.relatedTo && data.relatedTo.length > 0; else empty">
        <a *ngFor="let ca of data.relatedTo" [routerLink]="['/cases', ca.id]">{{
          ca.id
        }}</a>
      </ng-container>
      <ng-template #empty>-</ng-template>
    </div>

    <span>Sent to</span>
    <span>{{
      data.sentTo && data.sentTo.length > 0 ? data.sentTo[0] : '-'
    }}</span>

    <span>Time to resolution</span>
    <cm-label
      [color]="
        data.timeToResolution?.isPaused
          ? 'yellow'
          : data.timeToResolution?.isBreached
            ? 'red'
            : 'green'
      "
      >{{
        (data.timeToResolution?.breachTime | date: 'MMM dd, hh:mm a') ?? '-'
      }}</cm-label
    >

    <span>Time to first response</span>
    <cm-label
      [color]="
        data.timeToFirstResponse?.isPaused
          ? 'yellow'
          : data.timeToFirstResponse?.isBreached
            ? 'red'
            : 'green'
      "
      >{{
        (data.timeToFirstResponse?.breachTime | date: 'MMM dd, hh:mm a') ?? '-'
      }}</cm-label
    >
  </div>
</div>
