import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  standalone: true,
  imports: [MatIconModule, CommonModule, MatButtonModule],
})
export class DialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      confirmLabel: string;
      cancelLabel?: string;
      dialogAppearence: any;
      table?: any[];
      hideCancel: boolean;
    }
  ) {}

  @Output()
  onConfirm = new EventEmitter<Event>();

  @Output()
  onCancel = new EventEmitter<Event>();
}
