<cm-page-header
  [title]="'Edit ' + data.id"
  [breadcrumbList]="[
    { label: 'Cases', url: '/cases', hidden: false },
    { label: data.id, url: '/case/' + data.id, hidden: false },
    { label: 'Edit', url: '/case/' + data.id + '/edit', hidden: false },
  ]">
</cm-page-header>
<div class="cm-edit-page">
  <div class="cm-edit-form">
    <cm-basic-info [data]="data"></cm-basic-info>
    <form [formGroup]="editCaseForm" class="cm-edit-form">
      <app-content-wrapper>
        <app-title [number]="1" [title]="'Case details'" />
        <p>Trip data</p>
        <div class="form__row">
          <mat-form-field appearance="outline">
            <mat-label>From</mat-label>
            <input matInput formControlName="fromStation" />
            <!-- <mat-error
              *ngIf="editCaseForm.controls.fromStation.hasError('required')"
              >Field is required</mat-error> -->
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>To</mat-label>
            <input matInput formControlName="toStation" />
            <!-- <mat-error
              *ngIf="editCaseForm.controls.toStation.hasError('required')"
              >Field is required</mat-error> -->
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Line number</mat-label>
          <input matInput formControlName="line" />
          <!-- <mat-error
            *ngIf="editCaseForm.controls.toStation.hasError('required')"
            >Field is required</mat-error> -->
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Vehicle number</mat-label>
          <input matInput formControlName="vehicleNumber" />
          <!-- <mat-error
            *ngIf="editCaseForm.controls.vehicleNumber.hasError('required')"
            >Field is required</mat-error> -->
        </mat-form-field>
        <div class="form__row">
          <mat-form-field appearance="outline">
            <mat-label>Staff Member Id</mat-label>
            <input matInput formControlName="staffMemberId" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Role</mat-label>
            <input matInput formControlName="role" />
          </mat-form-field>
        </div>
        <p>Choose max. 3 categories of this case</p>
        <mat-form-field appearance="outline">
          <mat-label>Category 1</mat-label>
          <mat-select formControlName="category1">
            @for (category of cat1; track category) {
              <mat-option [value]="category">{{ category }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Category 2</mat-label>
          <mat-select formControlName="category2">
            @for (category of cat2; track category) {
              <mat-option [value]="category">{{ category }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Category 3</mat-label>
          <input matInput formControlName="category3" [disabled]="true" />
        </mat-form-field>
      </app-content-wrapper>

      <app-content-wrapper>
        <app-title [number]="2" [title]="'Description'" />
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
          <!-- <mat-error *ngIf="form.controls.description.hasError('required')">Field is required</mat-error> -->
        </mat-form-field>
        <div class="form__row">
          <mat-form-field appearance="outline">
            <mat-label>Event date</mat-label>
            <input matInput formControlName="eventDate" />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="event"></mat-datepicker-toggle>
            <mat-datepicker #event></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Reported at</mat-label>
            <input matInput formControlName="reportedAt" />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="reported"></mat-datepicker-toggle>
            <mat-datepicker #reported></mat-datepicker>
          </mat-form-field>
        </div>
        <mat-checkbox formControlName="isMissingInformation"
          >Case has missing information</mat-checkbox
        >
        <mat-checkbox formControlName="isNothingWrong"
          >Wrongdoing can’t be determined</mat-checkbox
        >
      </app-content-wrapper>
      <app-content-wrapper>
        <app-title [number]="3" [title]="'Customer info'" />
        <mat-form-field appearance="outline">
          <mat-label>Full name</mat-label>
          <input matInput formControlName="customerName" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="customerEmail" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>customerPhone</mat-label>
          <input matInput formControlName="customerPhone" />
        </mat-form-field>
        <mat-checkbox formControlName="requiredCustomerFeedbackAction"
          >Customer feedback required</mat-checkbox
        >
      </app-content-wrapper>
      <div class="cm-case-edit-footer__buttons">
        <button mat-stroked-button (click)="goBack()">Go Back</button>
        <button mat-flat-button (click)="send()">Save</button>
      </div>
    </form>

    <!-- <form class="cm-edit-form" [formGroup]="form"> -->
    <!-- <cm-basic-info-edit /> -->
    <!-- <cm-case-details-edit /> -->
    <!-- <cm-description-edit /> -->
    <!-- <cm-customer-info-edit /> -->
    <!-- </form> -->
  </div>
</div>
