import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { directives } from './directives';
import { pipes } from './pipes';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { DialogComponent } from './components/dialog/dialog.component';

@NgModule({
  declarations: [pipes, directives],
  exports: [pipes, directives],
  imports: [CommonModule, PageHeaderComponent, DialogComponent],
  providers: [DatePipe],
})
export class SharedModule {}
