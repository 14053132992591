import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-title',
	templateUrl: './title.component.html',
	styleUrls: ['./title.component.scss'],
  imports: [
    CommonModule
  ],
  standalone: true
})
export class TitleComponent {
	@Input() title: string = 'Title';
	@Input() number?: number;
  @Input() hideUnderline?: boolean = false;
}
