{
	"LOGIN": {
		"EMAIL": "E-mail",
		"PASSWORD": "Lösenord",
		"LOG_IN": "Logga in",
		"LOG_IN_BTN": "Logga in",
		"ERROR_REQUIRED": "Fältet är obligatoriskt!",
		"ERROR_MAIL": "Ange en giltig e-postadress",
		"ERROR_PASSWORD": "Felaktig inloggning eller lösenord",
		"ERROR_ACCOUNT_LOCKED": "Kontot är låst. Vänligen kontakta din chef.",
		"ERROR_OFFLINE": "Du är offline",
		"ERROR_UNKNOWN": "Okänt fel"
	},
	"DRAWER": {
		"DARKMODE": "Darkmode",
		"LOG_OUT": "Logga ut",
		"AREA": "Område"
	},
	"MENU": {
		"HOME": "Hem",
		"SCHEDULE": "Schema",
		"INFO": "Aktuell info",
		"POSITION": "Positionlista"
	},
	"NAV": {
		"LOADING": "Laddar..."
	},
	"USERINFO": {
		"EMAIL": "E-mail",
		"PHONE": "Phone",
		"ROLES": "Roles",
		"LOG_OUT": "Log out"
	}
}
