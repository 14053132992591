import { Component, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-content-wrapper',
	templateUrl: './content-wrapper.component.html',
	styleUrls: ['./content-wrapper.component.scss'],
})
export class ContentWrapperComponent {
	@Input() display: 'half-page' | 'full-page' | 'transparent' = 'full-page';
}
