<div class="main-header">
  <mat-icon
    (click)="drawer.toggle()"
    aria-hidden="false"
    aria-label="menu icon"
    fontIcon="menu"></mat-icon>
  <img class="logo-img" src="/assets/icons/vr_logo.svg" />
  Case Management
  <div class="middle-fill"></div>
  <cm-user-menu></cm-user-menu>
</div>
<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="side">
    <div class="side-nav">
      <mat-nav-list class="side-nav__list">
        <mat-list-item>
          <a mat-button [routerLink]="['/cases']">Customer feedback</a>
        </mat-list-item>
        <mat-list-item>
          <a mat-button href="https://thr2.fawful.se/">THR</a>
        </mat-list-item>
        <mat-list-item>
          <a mat-button href="https://ecase.fawful.se/">Ecase cases</a>
        </mat-list-item>
      </mat-nav-list>

      <mat-nav-list class="side-nav__list">
        <mat-list-item>
          <a mat-button [routerLink]="['/categories']">Categories</a>
        </mat-list-item>
      </mat-nav-list>
      <!-- <div class="app__build-version-container">
        <span>CM Version | {{ spaVersionId }}</span>
      </div> -->
    </div>
  </mat-drawer>

  <div class="example-sidenav-content">
    <app-loader-overlay></app-loader-overlay>
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
