<div class="cm-description" data-test-id="case-description">
  <div class="cm-description__content">
    <div class="cm-description__summary">
      <h3>Description</h3>
      <div *ngIf="data.description; else noDescription">
        <ng-container *ngFor="let desc of data?.description">
          <ng-container *ngIf="desc.type === descriptionType[0]">
            <ng-container *ngFor="let text of desc.content">
              <p *ngIf="text.type === descriptionType[1]">
                <span>{{ text?.text ?? '-' }}</span>
              </p>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <ng-template #noDescription>
        <span>-</span>
      </ng-template>
    </div>
    <div
      class="cm-description__external"
      *ngIf="data.isMissingInformation || data.isNothingWrong">
      <div
        class="cm-description__external__item"
        *ngIf="data.isMissingInformation">
        <span>Case is missing info</span>
      </div>
      <div class="cm-description__external__item" *ngIf="data.isNothingWrong">
        <span>Wrongdoing can’t be determined</span>
      </div>
    </div>
  </div>
</div>
