import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cm-bradcrumb',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bradcrumb.component.html',
  styleUrl: './bradcrumb.component.scss',
})
export class BradcrumbComponent {
  breadcrumbs: any[] = [];
  shownBreadcrumbs: any[] = [];
  localList: any[] = [];

  @Input() numberOfPages = 5;

  @Input() list = [] as any[];

  @Output() onClick = new EventEmitter<any>();

  @Output() currentBreadcrumb = new EventEmitter<any>();

  @Output() allBreadcrumbs = new EventEmitter<any[]>();

  @Output() visibleBreadcrumbs = new EventEmitter<any[]>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['list'] && changes['list'].currentValue) {
      this.localList = changes['list'].currentValue;
      this.drawBreadcrumbs();
    }
  }

  ngOnInit() {
    this.router.events
      .pipe(
        map((event: any) => event.routerEvent),
        filter(event => {
          return event instanceof NavigationEnd;
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.drawBreadcrumbs();
        });
      });
  }

  drawBreadcrumbs(): void {
    if (this.localList.length === 0) {
      this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    }
    this.shownBreadcrumbs = [];
    let addedDots = false;
    const cutPosition = this.numberOfPages - 2;
    const visibleBreadcrumbs = this.localList.length
      ? this.localList
      : this.breadcrumbs;

    visibleBreadcrumbs.forEach((breadcrumb: any, index: number) => {
      if (cutPosition > index + 1 || index >= this.breadcrumbs.length - 2) {
        this.shownBreadcrumbs.push({
          label: breadcrumb.label,
          url: breadcrumb.url,
          hidden: breadcrumb.hidden,
        });
      } else if (!addedDots) {
        addedDots = true;
        this.shownBreadcrumbs.push({ label: '...', url: '', hidden: true });
      }
    });

    this.allBreadcrumbs.emit(this.breadcrumbs);
    this.visibleBreadcrumbs.emit(this.shownBreadcrumbs);
  }

  public select(breadcrumb: any) {
    if (!this.isActive(breadcrumb)) {
      this.onClick.emit(breadcrumb);
      this.router.navigateByUrl(breadcrumb.url);
    }
  }

  public isActive(breadcrumb: any): boolean {
    return this.router.url === breadcrumb.url;
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: any[] = []
  ): any[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map(segment => segment.path)
        .join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data['breadcrumbLabel'];
      let isSkipped = false;
      if (child.snapshot.data['breadcrumbSkip']) {
        isSkipped = child.snapshot.data['breadcrumbSkip'];
      }
      if (label && !isSkipped) {
        breadcrumbs.push({ label, url, hidden: false });
      }

      breadcrumbs = this.createBreadcrumbs(child, url, breadcrumbs);
    }

    if (breadcrumbs.length) {
      const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
      this.currentBreadcrumb.emit(lastBreadcrumb);
    }

    return breadcrumbs;
  }
}
