import { Injectable } from '@angular/core';
import { DecodedToken } from './models/decoded-token';

@Injectable({
	providedIn: 'root',
})
export class JwtDecoderService {
	constructor() {}

	decode(token: string): DecodedToken {
		const parts = token.split('.');

		if (parts.length !== 3) {
			throw new Error('The provided token is not a valid JWT');
		}

		const payload = parts[1];
		return this.parsePayload(payload);
	}

	private parsePayload(payload: string): DecodedToken {
		const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);

		return JSON.parse(jsonPayload);
	}
}
