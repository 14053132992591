import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss'],
})
export class ErrorDisplayComponent {
  @Input() subTitle!: string;
  @Input() title!: string;
  @Input() description!: string;
  @Output() closeErrorClick = new EventEmitter<Event>();

  ngOnInit() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '600px',
      data: {
        message: this.subTitle,
        confirmLabel: 'Close',
        hideCancel: true,
        dialogAppearence: 'danger',
        buttonSizing: 'M',
        title: this.title,
      },
    });

    dialogRef.componentInstance.onConfirm.subscribe(() => {
      dialogRef.close();
      this.closeErrorClick.emit();
    });

    dialogRef.componentInstance.onCancel.subscribe(() => {
      dialogRef.close();
      this.closeErrorClick.emit();
    });
  }

  constructor(public dialog: MatDialog) {}
}
