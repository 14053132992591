<div class="breadcrumb">
  <ng-container *ngFor="let breadcrumb of shownBreadcrumbs; let last = last">
    <span
      class="path"
      [ngClass]="{ 'active-path': !isActive(breadcrumb) }"
      (click)="select(breadcrumb)"
      >{{ breadcrumb.label }}</span
    >
    <span class="divider" *ngIf="!last"> / </span>
  </ng-container>
</div>
