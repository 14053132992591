import { Component, Inject } from '@angular/core';
import { createCategoryFormGroup } from '@shared/utils/form-utils';
import { CategoryService } from '@shared/services/category.service';
import { CaseTypeInstance, CategoryInstance } from '../category.model';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { ContentWrapperComponent } from '@shared/components/content-wrapper/content-wrapper.component';
import { TitleComponent } from '@shared/components/title/title.component';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'cm-create-category',
  standalone: true,
  imports: [
    MatDialogContent,
    ReactiveFormsModule,
    ContentWrapperComponent,
    TitleComponent,
    MatFormField,
    MatSelect,
    MatOption,
    MatInput,
    MatLabel,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    MatCheckbox,
    MatDialogActions,
    MatButton,
    NgForOf,
    MatSuffix,
  ],
  templateUrl: './create-category.component.html',
  styleUrl: './create-category.component.scss',
})
export class CreateCategoryComponent {
  createForm = createCategoryFormGroup(null);
  caseTypeInstances: CaseTypeInstance[];
  selectedCaseTypeInstance: number | null;
  caseTypeInstanceCategories: CategoryInstance[] = [];

  constructor(
    private categoryService: CategoryService,
    public dialogRef: MatDialogRef<CreateCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { caseTypeInstances: CaseTypeInstance[], caseTypeInstanceCategories: CategoryInstance[], selectedCaseTypeInstance: number | null },
  ) {
    this.caseTypeInstances = data.caseTypeInstances;
    this.selectedCaseTypeInstance = data.selectedCaseTypeInstance;
    this.caseTypeInstanceCategories = data.caseTypeInstanceCategories;
    this.createForm = createCategoryFormGroup(this.selectedCaseTypeInstance);
  }

  createItem() {
    if (this.createForm.valid) {
      this.categoryService.createCategory(this.createForm.value).subscribe({
        next: () => {
          console.log('Category created');
          this.dialogRef.close(this.createForm.value);
        },
        error: (error) => {
          console.error('Error updating category details:', error);
        },
      });
    } else {
      this.createForm.markAllAsTouched();
      console.log('Form is invalid');
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
