import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PermissionsService} from '@shared/services/auth/permissions.service';
import {NotFoundComponent} from './not-found/not-found.component';
import {LayoutComponent} from '@shared/components/layout/layout.component';
import {LoginComponent} from 'src/app/login/login.component';
import {CreateCaseComponent} from './cases/create-case/create-case.component';
import {ListCasesComponent} from './cases/list-cases/list-cases.component';
import {DetailPageComponent} from './cases/detail-page/detail-page.component';
import {EditPageComponent} from './cases/edit-page/edit-page.component';
import {ListCategoriesComponent} from './categories/list-categories/list-categories.component';
import {MasterCategoriesComponent} from "./categories/master-categories/master-categories.component";
import { CreateCategoryComponent } from './categories/create-category/create-category.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: '', redirectTo: '/cases', pathMatch: 'full'},
      // Routes for cases
      {
        path: 'cases',
        component: ListCasesComponent,
        canActivate: [PermissionsService],
      },
      {
        path: 'case/:id',
        component: DetailPageComponent,
        canActivate: [PermissionsService],
      },
      {
        path: 'case/:id/edit',
        component: EditPageComponent,
        canActivate: [PermissionsService],
      },
      {
        path: 'create-case',
        component: CreateCaseComponent,
      },

      // Routes for categories
      {
        path: 'categories',
        component: MasterCategoriesComponent,
        canActivate: [PermissionsService],
      },
      {
        path: 'create-category',
        component: CreateCategoryComponent,
        canActivate: [PermissionsService],
      }
    ],
  },
  {
    path: 'logged-out',
    component: LoginComponent,
  },
  {path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
