<div class="master-details-container">
  <cm-page-header title="Categories">
    <button mat-flat-button color="primary" (click)="openCreateDialog()">
      <mat-icon
        aria-hidden="false"
        aria-label="plus icon"
        fontIcon="add"></mat-icon>
      Create new
    </button>
  </cm-page-header>

  <div class="master-details-content">
    <cm-list-categories class="master-details-list"></cm-list-categories>
  </div>
</div>
