<div class="cm-basic-info">
  <div class="cm-basic-info__details">
    <div class="cm-basic-info__details__content" data-test-id="case-id">
      <span>Case ID</span>
      <span>{{ data.id }}</span>
    </div>
    <div class="cm-basic-info__details__border"></div>
    <div class="cm-basic-info__details__content">
      <span>Reference ID</span>
      <span>{{ data.referenceId }}</span>
    </div>
    <div class="cm-basic-info__details__border"></div>
    <div class="cm-basic-info__details__content">
      <span>District</span>
      <span>{{ data.district }}</span>
    </div>
    <div class="cm-basic-info__details__border"></div>
    <div class="cm-basic-info__details__content">
      <span>Type</span>
      <span>{{ typeText(data.caseType) }}</span>
    </div>
  </div>
</div>
