import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth/auth.service';
import { Subject } from 'rxjs';

import { concatMap, from, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CaseModel, CustomerInfo } from 'src/app/cases/case.model';

type casesPayload = {
  startAt: number;
  total: number;
  filter: CaseFiltering;
  cases: CaseModel[];
};

type CaseFiltering = {
  caseId: string | null;
  referenceId: string | null;
  reportedAt: string | null;
  type: string | null;
  status: string | null;
  category: string | null;
};

export type casePayload = {
  case: CaseModel;
};

export type customerInfoPayload = {
  customerInfo: CustomerInfo;
};

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  private apiBaseUrl = environment.api;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  public getCases({
                    page,
                    pageSize,
                    sortColumn,
                    sortDirection,
                    caseId,
                    referenceId,
                    reportedAt,
                    type,
                    status,
                  }: {
    page: number;
    pageSize: number;
    sortColumn: string | null;
    sortDirection?: 'ASC' | 'DESC' | null;
    caseId?: string | null;
    referenceId?: string | null;
    reportedAt?: string | null;
    type?: string | null;
    status?: string | null;
  }): Observable<casesPayload> {
    const payload = {
      page,
      pageSize,
      sortColumn,
      sortDirection,
      caseId,
      referenceId,
      reportedAt,
      type,
      status,
    };

    return from(this.authService.getToken()).pipe(
      concatMap(token => {
        return this.http.post<casesPayload>(
          `${this.apiBaseUrl}/cases/getallcases`,
          payload,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        );
      }),
    );
  }

  public getCase({ id }: { id: string }): Observable<casePayload> {
    return from(this.authService.getToken()).pipe(
      concatMap(token => {
        return this.http.get<casePayload>(`${this.apiBaseUrl}/cases/${id}`, {
          // TODO: header should be moved to interceptor
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      }),
    );
  }

  public getCaseCustomerInfo({
                               id,
                             }: {
    id: string;
  }): Observable<customerInfoPayload> {
    return from(this.authService.getToken()).pipe(
      concatMap(token => {
        return this.http.get<customerInfoPayload>(
          `${this.apiBaseUrl}/cases/${id}/customerinfo`,
          {
            // TODO: header should be moved to interceptor
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        );
      }),
    );
  }

  public createCase(payload: any) {
    const result = new Subject<any>();
    const url = `${this.apiBaseUrl}/cases`;
    const request = {
      method: 'POST',
      url: url,
      headers: new Headers(),
      mapper: null,
      body: payload,
      callback: (response: any) => {
        result.next(response);
      },
    };

    let options_ : any = {
      body: payload,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Accept": "text/plain"
      })
    };

    return this.http.request("post", url, options_)
  }

  update(id: string, model: Partial<CaseModel>) {
    return from(this.authService.getToken()).pipe(
      concatMap(token => {
        return this.http.patch(`${this.apiBaseUrl}/cases/${id}`, model, {
          headers: {
            authorization: `Bearer ${token}`,
          },
          responseType: 'text',
        });
      }),
    );
  }

  addComment(
    id: string,
    comment: string
  ) {
    const model = {
      content: comment,
    };
    return from(this.authService.getToken()).pipe(
      concatMap(token => {
        return this.http.post(`${this.apiBaseUrl}/cases/${id}/comment`, model, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      }),
    );
  }
}
