import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { CaseModel, caseState } from '../../case.model';

@Component({
  selector: 'cm-action-details',
  templateUrl: './action-details.component.html',
  styleUrls: ['./action-details.component.scss'],
})
export class ActionDetailsComponent implements OnInit, OnDestroy {
  private changeStateSubject = new Subject<caseState>();

  private changeStateSubs = Subscription.EMPTY;

  @Input() data!: CaseModel;

  private inProgress = new Subject<boolean>();
  isProgress$ = this.inProgress.asObservable();

  constructor() {}

  ngOnDestroy(): void {
    this.changeStateSubs.unsubscribe();
  }

  ngOnInit(): void {
    // this.changeStateSubs = this.changeStateSubject
    //   .pipe(
    //     debounceTime(300),
    //     tap(() => this.inProgress.next(true)),
    //     switchMap(state => this.facade.changeState(state)),
    //     tap(() => this.inProgress.next(false)),
    //     withLatestFrom(this.changeStateSubject),
    //     tap(([, cs]) => (this.data.status = cs)) // prevent state blinking after page refresh
    //   )
    //   .subscribe({
    //     next: () => this.facade.updateRefreshToken(),
    //     error: () => this.inProgress.next(false),
    //     complete: () => this.inProgress.next(false),
    //   });
  }

  changeStateInfo(state: caseState) {
    this.changeStateSubject.next(state);
  }
}
