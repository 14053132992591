import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type pillColor = 'red' | 'green' | 'yellow' | 'orange' | 'gray' | 'blue';

@Component({
    standalone: true,
    selector: 'app-status-badge',
    templateUrl: './status-badge.component.html',
    styleUrls: ['./status-badge.component.scss'],
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusBadgeComponent {
  @Input() color!: pillColor;
  @Input() backgroundColor!: string;
  @Input() dotColor!: string;
  @Input() showDot = false;
  @Input() text!: string;
}
