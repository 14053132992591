export const environment = {
    spaVersionId:"3",
	production: false,
	authUrl: 'https://cognito-idp.eu-west-1.amazonaws.com/',
	api: 'https://api.case-management.vrtest.io',
	cognito: {
		userPoolId: 'eu-west-1_cJbEV1tgM',
		userPoolWebClientId: '456snu9hlooj2rnuk6e92j8f1t',
		idpName: 'AzureAD',
		domain: 'auth.case-management.vrtest.io',
		loginUrl: 'https://case-management.vrtest.io',
		logoutUrl:'https://case-management.vrtest.io/logged-out',
		region: 'eu-west-1',
	},
};
