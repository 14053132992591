import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AuthService } from '@shared/services/auth/auth.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'cm-user-menu',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatIconModule],
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
})
export class UserMenuComponent {
  public userModel = { name: '' };

  async ngOnInit(): Promise<void> {
    this.authService.GetCurrentUser().then(x => {
      this.userModel.name = x.name;
    });
  }

  public logoutClicked() {
    this.authService.logout();
  }

  constructor(public authService: AuthService) {}
}
