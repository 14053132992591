import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { GlobalVariablesService } from '@shared/services/global-variables.service';
import { MatSelectChange } from '@angular/material/select';
import { SelectOption } from '../../../cases/case.model';

@Component({
  selector: 'cm-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  spaVersionId = environment.spaVersionId;
  showFiller = false;
  activeRoute: any;
  title = 'Case Management';

  districts: SelectOption[] = [{ value: '940', viewValue: 'Tåg i Bergslagen' }];
  selected = '940';
  public unfolded = false;

  public isCurrent = (item: any): boolean =>
    (item === this.router.url || this.router.url.indexOf(item) === 0) &&
    item !== '';

  handleSelection(event: MatSelectChange) {
    this.globals.setDistrict(event.value);
  }

  public isHome = (): boolean => 
    this.router.url == '/';

  public toggleSidebar(): void {
    this.unfolded = !this.unfolded;
  }

  constructor(
    private router: Router,
    private globals: GlobalVariablesService
  ) {
    this.globals.setDistrict(this.selected);
  }
}
