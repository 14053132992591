import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatTreeModule } from '@angular/material/tree';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { VrStorybookLibModule } from '@vr/vr-storybook-lib';
import { LoaderInterceptor } from '@shared/interceptors/loader.interceptor';
import { AuthInterceptor } from '@shared/services/auth/authInterceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderOverlayComponent } from '@shared/components/loader-overlay/loader-overlay.component';
import { ErrorInterceptor } from '@shared/interceptors/error.interceptor';
import { NotFoundComponent } from './not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from '@shared/components/layout/layout.component';
import { LoginComponent } from './login/login.component';
import { PageHeaderComponent } from '@shared/components/page-header/page-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { L10nService } from '@vr/vr-common-lib';
import { ErrorDisplayComponent } from '@shared/components/error-display/error-display.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { UserMenuComponent } from '@shared/components/user-menu/user-menu.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { SharedModule } from '@shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContentWrapperComponent } from '@shared/components/content-wrapper/content-wrapper.component';
import { TitleComponent } from '@shared/components/title/title.component';
import { DetailPageComponent } from './cases/detail-page/detail-page.component';
import { EditPageComponent } from './cases/edit-page/edit-page.component';
import { BasicInfoComponent } from './cases/components/basic-info/basic-info.component';
import { DescriptionComponent } from './cases/components/description/description.component';
import { CommentsComponent } from './cases/components/comments/comments.component';
import { ActionDetailsComponent } from './cases/components/action-details/action-details.component';
import { CaseDetailsComponent } from './cases/components/case-details/case-details.component';
import { CustomerInfoComponent } from './cases/components/customer-info/customer-info.component';
import { LabelComponent } from '@shared/components/label/label.component';
import { StatusBadgeComponent } from '@shared/components/badge/status-badge.component';
import { DateFormatPipe } from '@shared/pipes/date-format.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ListCasesComponent } from './cases/list-cases/list-cases.component';
import { ListCategoriesComponent } from './categories/list-categories/list-categories.component';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    ErrorDisplayComponent,
    LayoutComponent,
    DetailPageComponent,
    EditPageComponent,
    BasicInfoComponent,
    DescriptionComponent,
    CommentsComponent,
    ActionDetailsComponent,
    CaseDetailsComponent,
    CustomerInfoComponent,
    ListCasesComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    LoaderOverlayComponent,
    PageHeaderComponent,
    MatListModule,
    MatIconModule,
    MatTreeModule,
    MatDividerModule,
    UserMenuComponent,
    MatDialogModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    VrStorybookLibModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
    PageHeaderComponent,
    MatCheckboxModule,
    DialogComponent,
    CommonModule,
    SharedModule,
    MatTooltipModule,
    ContentWrapperComponent,
    TitleComponent,
    LabelComponent,
    StatusBadgeComponent,
    DateFormatPipe,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    ListCategoriesComponent
  ],
  providers: [
    L10nService,
    DatePipe,
    {
      provide: 'translation',
      useValue: require('../assets/translations/sv.json'),
    },
    {
      provide: 'defaultTranslation',
      useValue: require('../assets/translations/en.json'),
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'ISO' },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
