<div class="login">
  <div class="login__shadow">
    <div class="stripes-container">
      <div class="stripe"></div>
      <div class="stripe"></div>
      <div class="stripe"></div>
      <div class="stripe"></div>
      <div class="stripe"></div>
      <div class="stripe"></div>
    </div>
    <div class="login__card">
      <h1>Log in</h1>
      <p>Welcome to Case Management.</p>
      <p>Please log in with your VR User Account to access this portal.</p>
      <br />
      <button mat-flat-button (click)="login()">
        Log In
      </button>
    </div>
  </div>
</div>
