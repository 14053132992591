import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaseService } from '@shared/services/case.service';
@Component({
  selector: 'cm-detail-page',
  templateUrl: './detail-page.component.html',
  styleUrls: ['./detail-page.component.scss'],
})
export class DetailPageComponent {
  public data: any;
  constructor(
    public caseService: CaseService,
    private route: ActivatedRoute
  ) {
    this.caseService
      .getCase({ id: this.route.snapshot.params['id'] })
      .subscribe(data => {
        this.data = data.case;
      });
  }
}
