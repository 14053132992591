<cm-page-header title="Cases">
  <button mat-flat-button color="primary" [routerLink]="['/create-case']">
    <mat-icon
      aria-hidden="false"
      aria-label="plus icon"
      fontIcon="add"></mat-icon>
    Create new
  </button>
</cm-page-header>

<form class="cases__filters" [formGroup]="form" (ngSubmit)="filterCases()">
  <mat-form-field appearance="outline">
    <mat-label>Case ID:</mat-label>
    <input matInput formControlName="caseId" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Reference ID:</mat-label>
    <input matInput formControlName="referenceId" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Reported At:</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="reportedAt" />
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Status:</mat-label>
    <mat-select formControlName="status">
      <mat-option [value]="">--</mat-option>
      <mat-option [value]="'Closed'">Closed</mat-option>
      <mat-option [value]="'Open'">Open</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-stroked-button class="apply-filter-button">Filter</button>
</form>
<div class="table-framed">
  <table #table mat-table [dataSource]="casesListing" [matSortActive]="sortColumn" [matSortDirection]="sortDirection" matSort class="table" matSortDisableClear>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Case ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="referenceId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference ID</th>
      <td mat-cell *matCellDef="let element">{{ element.referenceId }}</td>
    </ng-container>
    <ng-container matColumnDef="reportedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Reported</th>
      <td mat-cell *matCellDef="let element">
        {{ element.reportedAt | dateFormatPipe }}
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">Customer Feedback</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">
        <app-status-badge
          [color]="element.status == 'open' ? 'red' : 'gray'"
          [showDot]="true"
          [text]="element.status"></app-status-badge>
      </td>
    </ng-container>
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>Categories</th>
      <td mat-cell *matCellDef="let element">
        <div class="category" *ngIf="element.category1">
          {{ element.category1 }}
        </div>
        <div class="category" *ngIf="element.category2">
          {{ element.category2 }}
        </div>
        <div class="category" *ngIf="element.category3">
          {{ element.category3 }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="additionalInfo">
      <th mat-header-cell *matHeaderCellDef>Additional Info</th>
      <td mat-cell *matCellDef="let element">
        <app-status-badge
          *ngIf="element.isMissingInformation"
          [color]="'gray'"
          [showDot]="true"
          [text]="'Missing info'"></app-status-badge>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
      class="table__head"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="viewDetails(row.id)"
      class="table__row"></tr>
  </table>
  <mat-paginator
    #paginator
    (page)="pageChangeEvent($event)"
    [length]="totalRecords"
    [pageSize]="20"
    [pageSizeOptions]="[20, 50]"
    [showFirstLastButtons]="true">
  </mat-paginator>
</div>
