import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@shared/services/auth/auth.service';
import { environment } from '../../../environments/environment';
import { concatMap, from, Observable } from 'rxjs';
import {
  CategoryDetails,
  CaseTypeInstance,
  CategoryListResponse,
  CategoryListRequest,
} from 'src/app/categories/category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private apiBaseUrl = environment.api;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  public getAllCategories(model: CategoryListRequest): Observable<CategoryListResponse> {
    return from(this.authService.getToken()).pipe(
      concatMap(token => {
        let url = `${this.apiBaseUrl}/CaseCategories/list`;
        return this.http.post<CategoryListResponse>(
          url,
          model,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        );
      }),
    );
  }

  public getCategoryDetails(id: number): Observable<CategoryDetails> {
    return from(this.authService.getToken()).pipe(
      concatMap(token => {
        return this.http.get<CategoryDetails>(
          `${this.apiBaseUrl}/CaseCategories/${id}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        );
      }),
    );
  }

  public deleteCategory(id: number) {
    return from(this.authService.getToken()).pipe(
      concatMap(token => {
        return this.http.delete(
          `${this.apiBaseUrl}/CaseCategories/${id}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        );
      }),
    );
  }

  updateCategoryDetails(model: Partial<CategoryDetails>) {
    return from(this.authService.getToken()).pipe(
      concatMap(token => {
        return this.http.put(`${this.apiBaseUrl}/CaseCategories`, model, {
          headers: {
            authorization: `Bearer ${token}`,
          },
          responseType: 'text',
        });
      }),
    );
  }

  createCategory(
    model: CategoryDetails,
  ) {

    return from(this.authService.getToken()).pipe(
      concatMap(token => {
        return this.http.post(`${this.apiBaseUrl}/CaseCategories`, model, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      }),
    );
  }

  public getAllCaseTypeInstances(): Observable<CaseTypeInstance[]> {
    return from(this.authService.getToken()).pipe(
      concatMap(token => {
        return this.http.get<CaseTypeInstance[]>(
          `${this.apiBaseUrl}/CaseType/instances`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        );
      }),
    );
  }
}
